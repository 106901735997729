import React, { useEffect, useState } from 'react'
import config from "../config"

export const RootContext = React.createContext()

export default ({ children }) => {
  const prevAuth = window.localStorage.getItem('auth') || '{}'
  const [ auth, setAuth ] = useState(JSON.parse(prevAuth))
  const [ message, setMessage ] = useState(null)
  const [ defaultView, setDefaultView ] = useState(window.localStorage.getItem('defaultView') || 'map')

  let messageTimeout = null

  useEffect(
    () => {
      window.localStorage.setItem('auth', JSON.stringify(auth))
      window.localStorage.setItem('defaultView', defaultView)
    },
    [auth, defaultView]
  )

  const signInOauth = (provider, code, redirect) => {
    const fetchConfig = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify({provider, code})
    }
    const url = `${config.apiUrl}/oauth/sign_in`
    _postSignIn(url, fetchConfig, redirect)
  }

  const signIn = (creds, redirect) => {
    const fetchConfig = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify({user: {email: creds.email, password: creds.password}})
    }
    const url = `${config.apiUrl}/auth/sign_in`
    _postSignIn(url, fetchConfig, redirect)
  }

  const _postSignIn = (url, fetchConfig, redirect) => {
    fetch(url, fetchConfig).then(response => response.json().then(user => ({user, response}))).then(
      ({user, response}) => {
        if (!response.ok) {
          console.log("Auth error response")
          return Promise.reject(response)
        } else {
          console.log("Auth success")
          // If login was successful, set the token in local storage
          const jwt = response.headers.get("Authorization").replace(/^Bearer /, '')
          setAuth({jwt, user})
          window.location.href = redirect
        }
      }).catch(err => {
      console.log(err.statusCode)
      console.log(err)
      if (err.status && err.status === 401){
        showMessage('Wrong username/password', 'danger')
      } else {
        showMessage('Login network error!', 'danger')
      }
    })
  }

  const signOut = () => {
    const fetchConfig = {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type':'application/json',
        'Authorization': `Bearer ${auth.jwt}`
      }
    }

    const url = `${config.apiUrl}/auth/sign_out`

    fetch(url, fetchConfig).then(response => {
      showMessage("Signed out from all devices")
      //window.location.href = '/'
      //console.log(response)
    }).catch(err => {
      console.log(err)
      showMessage("Unable to sign out from all devices!", 'danger')
    })
  }

  const testSession = () => {
    const fetchConfig = {
      method: 'GET',
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${auth.jwt}`
      }
    }

    return fetch(`${config.jsonApiUrl}users/${auth.user.id}`, fetchConfig).then(response => {
      if (response.status === 401) {
        showMessage("Session has expired! Sign in required", "warning")
        setAuth({})
        //window.location.href = '/'
      } else {
        console.log(response)
        showMessage("Unable to fetch resources!", "danger")
      }
    }).catch(err => {
      console.error(err)
      showMessage("Network error! Unable to connect to the server", "danger")
    })
  }

  const isAuthenticated = () => {
    return auth && auth.jwt ? true : false
  }

  const showMessage = (msg, type = 'success') => {
    setMessage({text: msg, type: type})
    if (messageTimeout != null)
      window.clearTimeout(messageTimeout)
    messageTimeout = window.setTimeout(() => {setMessage(null)}, 10000)
  }

  const clearMessage = () => {
    setMessage(null)
  }

  const canCreate = (endpoint) => {
    if (auth.user && auth.user.admin)
      return true
    return auth.user && auth.user.permissions[endpoint] && auth.user.permissions[endpoint].create
  }

  const canIndex = (endpoint) => {
    if (auth.user && auth.user.admin)
      return true
    return auth.user && auth.user.permissions[endpoint] && auth.user.permissions[endpoint].index
  }

  const defaultContext = {
    auth,
    setAuth,
    isAuthenticated,
    message,
    showMessage,
    clearMessage,
    defaultView,
    setDefaultView,
    canCreate,
    canIndex,
    testSession,
    signIn,
    signOut,
    signInOauth
  }

  return (
    <RootContext.Provider value={defaultContext}>
      {children}
    </RootContext.Provider>
  )
}