import React, {useEffect, useState} from "react"
import Time from "../lib/Time"
import styles from '../styles/OrganizationReports.module.css'
import {Spinner} from "react-bootstrap"
import {Organization} from "../Resources"

export default () => {
  const [organizations, setOrganizations] = useState([])
  const [loading, setLoading] = useState(false)
  const [reportsLoaded, setReportsLoaded] = useState(0)

  useEffect(() => {
    setLoading(true)
    Organization.where({root: true}).page({pageSize: 1000}).all().then((collection) => {
      setOrganizations(collection.toArray())
    }).finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    loadReports(organizations)
  }, [organizations])

  const loadReports = async (organizations) => {
    setLoading(true)
    for (const organization of organizations) {
      await organization.loadDeviceActivityReport()
      setReportsLoaded(amount => amount + 1)
    }
    setLoading(false)
  }

  const parseDate = (date) => {
    const year = date.substr(0, 4)
    const month = date.substring(4)
    return new Date(year, month, 0)
  }

  // Return months of last full quarter
  const months = () => {
    const month = new Date().getMonth() + 1
    if (month > 3 & month < 7) {
      return [1, 2, 3]
    } else if (month > 6 && month < 10) {
      return [4, 5, 6]
    } else if (month > 9 && month <= 12) {
      return [7, 8, 9]
    } else {
      return [10, 11, 12]
    }
  }

  const price = (amount) => {
    return Math.ceil( amount * Math.max(2.5, 5 - amount * 0.005))
  }

  const formatPrice = (price) => {
    return (Math.floor(price * 100) / 100).toFixed(2)
  }

  const deviceActivityReportItems = (organization) => {
    const report = organization.deviceActivityReport()
    if (!report) {
      return []
    }
    const data = Object.entries(report.report).filter(item => {
      const month = item[0].substring(4)
      return months().includes(parseInt(month, 10))
    })
    const formattedData = data.map(item => [item[0], `${item[1]} (${formatPrice(price(item[1]))} €)`])
    return [...formattedData.sort((a, b) => (
      parseDate(a[0]).getTime() - parseDate(b[0]).getTime()
    )), [
      'Total devices',
      data.reduce((sum, [date, value]) => sum + value, 0)
    ], [
      'Total price',
      `${formatPrice(data.reduce((sum, [date, value]) => sum + price(value), 0))} €`
    ]]
  }

  return (
    <div>
      <h5>Device activity report</h5>
      {organizations.filter(org => org.deviceActivityReport()).map(org =>
        <div style={{marginTop: 10}}>
          <h6>{org.name}</h6>
          <div className={styles.deviceActivityReport}>
            {deviceActivityReportItems(org).map(([date, value], index) => (
              <div key={date} className={styles.deviceActivityReportItem}>
                <div className={styles.deviceActivityReportItemDate}>
                  {date !== 'Total devices' && date !== 'Total price' &&
                  <Time timeFormat={null} dateFormat="months">{parseDate(date)}</Time>}
                  {(date === 'Total devices' || date === 'Total price') &&
                  <span>{date}</span>}
                </div>
                <div className={index % 2 === 0 ? styles.deviceActivityReportItemValue : styles.deviceActivityReportItemValueOdd}>
                  {value}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {loading &&
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>}
    </div>
  )
}