import React, { useContext, useState } from 'react'
import {Button, Modal} from "react-bootstrap"
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {RootContext} from "../contexts/RootContext"
import FormCheckbox from "../lib/FormCheckbox"

export default ({show, onClose}) => {
  const { auth, signOut, setAuth, showMessage } = useContext( RootContext )
  const [ signOutServer, setSignOutServer ] = useState(false)

  const handleSignOut = () => {
    onClose()
    if (signOutServer)
      signOut()
    else
      showMessage("Signed out")
    setAuth({})
  }

  return(
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Sign out</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Signed in as <strong>{auth.user && auth.user.name}</strong>
        </p>
        <p>
          <strong>Are you sure to end the session?</strong>
        </p>
        <FormCheckbox
          name="signOutFromAllDevices"
          checked={signOutServer}
          onChange={() => setSignOutServer(!signOutServer)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSignOut}>
          Sign out
          &nbsp;
          <FontAwesomeIcon icon={faSignOutAlt}/>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}