import Base from './Base'

class Tag extends Base {
  static className = 'Tag'
  static define() {
    this.belongsTo('organization')
    this.hasMany('instruments')
    this.attributes(
      'name'
    )
  }
}

export default Base.createResource(Tag)