import React, {useEffect, useState} from "react"
import FormCheckbox from "../lib/FormCheckbox"
import {InstrumentAlarmCommand} from "../Resources"
import FormSelect from "../lib/FormSelect"

export default ({ resource, commands, setCommands }) => {
  const [ show, setShow ] = useState(false)
  const [ commandOptions, setCommandOptions ] = useState([])

  useEffect(() => {
    loadCommands()
    if (commands.length > 0) {
      setShow(true)
    }
  }, [])

  const loadCommands = () => {
    resource.instrument().organization().commands()
      .where({}).perPage(200).order({name: 'asc'}).all().then(cmds => setCommandOptions(cmds.toArray()))
  }

  const toggleShow = () => {
    if (show) {
      setCommands([])
    }
    setShow(!show)
  }

  // Find InstrumentAlarmCommand for the cause
  const resolveCommand = (cause) => {
    return commands.find(cmd => cmd.cause === cause)
  }

  const handleCommandChange = (ev) => {
    const cause = ev.target.name
    const commandId = parseInt(ev.target.value, 10)
    const commandOption = commandOptions.find(opt => parseInt(opt.id, 10) === commandId)
    const command = resolveCommand(cause)
    if (commandId === 0) {
      if (command) {
        const index = commands.indexOf(command)
        const newCommands = [...commands]
        newCommands.splice(index, 1)
        setCommands(newCommands)
      }
      return
    }
    if (command) {
      command.assignCommand(commandOption)
      setCommands([...commands])
    } else {
      const newCommand = new InstrumentAlarmCommand()
      newCommand.assignAttributes({ cause })
      newCommand.assignInstrumentAlarm(resource)
      newCommand.assignCommand(commandOption)
      setCommands([...commands, newCommand])
    }
  }

  return (
    <React.Fragment>
      <FormCheckbox name="showCommands" label="Commands" checked={show} onChange={toggleShow} />
      {show && <div>
        <FormSelect
          horizontal
          key="commandValueOfRange"
          name="value_off_range"
          label="Value off range"
          value={resolveCommand("value_off_range")?.command()?.id}
          options={[[0,  '- choose -']].concat(commandOptions.map(p => [p.id, p.name]))}
          onChange={handleCommandChange}
        />
        <FormSelect
          horizontal
          key="commandIssuesCleared"
          name="issues_cleared"
          label="Issues cleared"
          value={resolveCommand("issues_cleared")?.command()?.id}
          options={[[0,  '- choose -']].concat(commandOptions.map(p => [p.id, p.name]))}
          onChange={handleCommandChange}
        />
      </div>}
    </React.Fragment>
  )
}
