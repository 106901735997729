import React, {useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import FormInput from '../lib/FormInput'
import FormSelect from '../lib/FormSelect'
import Organization from "../resources/Organization"
import withResourceModal from "../hocs/withResourceModal"

const TagModal = ({formState, errors, handleInputChange}) => {
  const [ organizations, setOrganizations ] = useState([])

  useEffect(
    () => {
      Organization.perPage(1000).all().then(orgs => setOrganizations(orgs.toArray()))
    },
    []
  )

  return (
    <Form>
      <FormSelect
        disabled
        name="organization"
        label="Organization"
        options={organizations.map(org => [org.id, org.name])}
        value={formState.organization && formState.organization.id}
        error={errors.organization}
        onChange={handleInputChange}/>
      <FormInput name="name" value={formState.name} error={errors.name} onChange={handleInputChange}/>
    </Form>
  )
}

export default withResourceModal(TagModal)