import {useState, useEffect, useRef} from 'react'

const getWindowDimensions = () => ({windowHeight: window.innerHeight, windowWidth: window.innerWidth})

export default () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const timeOutRef = useRef(null)

  const handleResize = () => {
    if (timeOutRef.current)
      window.clearTimeout(timeOutRef.current)
    timeOutRef.current = window.setTimeout(() => {
      setWindowDimensions(getWindowDimensions())
    }, 250)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}