import {Form} from "react-bootstrap"
import FormInput from "../lib/FormInput"
import React, {useContext, useEffect, useState} from "react"
import {ResourceContext} from "../contexts/ResourceContext"
import ResourceModal from "./ResourceModal"

const DeviceConfigModal = ({resource, show, onClose}) => {
  const [ formState, setFormState ] = useState({})
  const { reloadResources } = useContext(ResourceContext)
  const [ errors, setErrors ] = useState({})

  useEffect(
    () => {
      if (resource){
        setFormState({
          deviceName: resource.deviceName,
          description: resource.description,
          config: JSON.stringify(resource.config, null, 2)
        })
      }
      setErrors({})
    },
    [resource]
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox')
      value = ev.target.checked
    let state = {...formState, [name]: value}
    setFormState(state)
  }

  const save = () => {
    const state = Object.assign({}, formState)
    try {
      state.config = JSON.parse(state.config)
    } catch (e) {
      console.log('JSON parse error:', e)
      setErrors({ config: 'Is not valid JSON' })
      return
    }
    resource.assignAttributes(state)
    const msg = resource.persisted() ? 'Config updated' : 'Config created'
    return resource.save().then(() => {
      reloadResources(msg)
      onClose()
    }).catch(err => {
      let errors = {}
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  return (
    <ResourceModal
      show={show}
      onClose={onClose}
      onSubmit={save}
      resource={resource}
      label="automation"
    >
      <Form>
        <FormInput name="deviceName" value={formState.deviceName} error={errors.deviceName} onChange={handleInputChange}/>
        <FormInput
          placeholder="some description of device"
          rows={2}
          name="description"
          value={formState.description}
          error={errors.description}
          onChange={handleInputChange}
        />
        <FormInput
          placeholder="device config in json format"
          rows={10}
          name="config"
          value={formState.config}
          error={errors.config}
          onChange={handleInputChange}
        />
      </Form>
    </ResourceModal>
  )
}

export default DeviceConfigModal
