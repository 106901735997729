import Base from './Base'

class Command extends Base {
  static className = 'Command'
  static define() {
    this.belongsTo('organization')
    this.hasMany('userGroups')
    this.attributes('name', 'description', 'downlinks')
  }

  postRun(){
    const config = {
      method: 'POST',
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem('auth') || '{}').jwt}`
      },
      body: JSON.stringify({
        data: {
          type: 'command_runs'
        }
      })
    }

    const url = this.__links.runs

    return fetch(url, config).then(response => response.json().then(data => ({data, response}))).then(
      ({data, response}) => {
        if (!response.ok)
          return Promise.reject(data)
      }
    )
  }
}

export default Base.createResource(Command)
