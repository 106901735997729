import React, {useContext, useEffect, useState} from 'react'
import {Button, Form, Row, Col} from 'react-bootstrap'
import {ResourceContext} from "../contexts/ResourceContext"
import FormInput from '../lib/FormInput'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarked, faBullseye} from "@fortawesome/free-solid-svg-icons";
import FormDateTimePicker from "../lib/FormDateTimePicker"
import { InstrumentCalibrationSensor } from "../Resources"
import WorldMap from "../lib/WorldMap"
import ResourceModal from "./ResourceModal"
import uncamelize from "../lib/uncamelize"
import styles from '../styles/InstrumentCalibrationModal.module.css'
import FormCheckbox from "../lib/FormCheckbox"
import InstrumentCalibrationModalSensors from "./InstrumentCalibrationModalSensors"

export default ({resource, show, onClose, instrumentCalibrationSensors}) => {
  const { reloadResources } = useContext(ResourceContext)
  const [ formState, setFormState ] = useState({})
  const [ characteristics, setCharacteristics ] = useState({})
  const [ errors, setErrors ] = useState({})
  const [ showMap, setShowMap ] = useState(false)
  const [ selectedSensors, setSelectedSensors ] = useState({})

  useEffect(
    () => {
      if (resource){
        setFormState({
          validFrom: resource.validFrom,
          longitude: resource.longitude,
          latitude: resource.latitude,
          offline: resource.offline
        })
        if (resource.characteristics)
          setCharacteristics(resource.characteristics)
        else {
          let chars = {}
          // Predefine some common characteristics for new record
          if (instrument() && instrument().requiredAttributes.includes('correction'))
            chars['correction'] = '0'
          setCharacteristics(chars)
        }
      }
      setErrors({})
    },
    [resource]
  )

  const instrument = () => {
    return resource && resource.instrument && resource.instrument()
  }

  const handleCharacteristicsChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    setCharacteristics({...characteristics, [name]: value})
  }

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox')
      value = ev.target.checked
    setFormState({...formState, [name]: value})
  }

  const saveInstrumentCalibrationSensor = (key) => {
    const existingCalibrationSensor = resource.instrumentCalibrationSensors().toArray().find(s => s.key === key)
    const sensor = selectedSensors[key]
    // Sensor has been calibrated with earlier, now set cleared by user?
    if (!sensor && existingCalibrationSensor)
      return existingCalibrationSensor.destroy()
    else if (!sensor)
      return
    if (!existingCalibrationSensor){
      const newItem = InstrumentCalibrationSensor.build({
        sensor: sensor,
        key: key,
        instrumentCalibration: resource
      })
      return newItem.save()
    } else {
      return existingCalibrationSensor.updateSensor(sensor)
    }
  }

  const saveInstrumentCalibrationSensors = async () => {
    for (let key of Object.keys(selectedSensors)){
      await saveInstrumentCalibrationSensor(key)
    }
  }

  const validateSensors = () => {
    for (let sensor of instrument().requiredSensors){
      const option = instrument().optionalSensors.find(s => s.optionFor === sensor.key)
      const optionalKey = option ? option.key : null
      if (optionalKey !== null && selectedSensors[optionalKey] !== null && selectedSensors[sensor.key] !== null){
        setErrors({...errors, [`${sensor.key}Sensor`]: "Must not be selected WITH option"})
        return false
      } else if (selectedSensors[sensor.key] === null && (!optionalKey || selectedSensors[optionalKey] === null)){
        if (optionalKey)
          setErrors({...errors, [`${sensor.key}Sensor`]: "This OR option must be selected"})
        else
          setErrors({...errors, [`${sensor.key}Sensor`]: "Must be selected"})
        return false
      }
    }
    return true
  }

  const save = () => {
    if (!formState.offline && !validateSensors())
      return
    let state = Object.assign({}, formState)
    resource.assignAttributes({...state, characteristics: characteristics})
    const msg = resource.persisted() ? 'Calibration updated' : 'Calibration created'
    return resource.save().then(() => {
      if (!resource.offline){
        saveInstrumentCalibrationSensors().then(() => {
          reloadResources(msg)
          onClose()
        })
      } else {
        reloadResources(msg)
        onClose()
      }
    }).catch(err => {
      let errors = {}
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  const handleMapClick = (event) => {
    setFormState({...formState, latitude: event.latLng.lat(), longitude: event.latLng.lng()})
  }

  const worldMapCenter = () => {
    if (worldMapMarkers().length > 0)
      return worldMapMarkers()[0]
    else
      return undefined
  }

  const worldMapMarkers = () => {
    if (formState.latitude && formState.longitude) {
      return [{
        lat: parseFloat(formState.latitude),
        lng: parseFloat(formState.longitude)
      }]
    } else
      return []
  }

  const trackLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setFormState({...formState, latitude: position.coords.latitude, longitude: position.coords.longitude})
      })
    } else {
      console.error("No geolocation support")
    }
  }

  const characteristicsPlaceholder = (attr) => {
    if (attr === 'formula')
      return 'e.g. value * 2'
    else
      return null
  }

  return (
    <ResourceModal show={show} onClose={onClose} onSubmit={save} resource={resource} label="calibration" className={styles.modalDialog}>
      <Form>
        {instrument() && <h6>{instrument().fullName}</h6>}
        <hr/>
        <FormDateTimePicker name="validFrom"
                            horizontal
                            value={formState.validFrom}
                            error={errors.validFrom}
                            onChange={handleInputChange}/>
        <Row>
          <Col sm={4}>
          </Col>
          <Col>
            <FormCheckbox name="offline"
                          checked={formState.offline}
                          onChange={handleInputChange}/>
          </Col>
          <Col style={{textAlign: 'right'}}>
            {!formState.offline &&
            <React.Fragment>
              <Button variant="dark" onClick={() => setShowMap(!showMap)} className={styles.mapButton}>
                <FontAwesomeIcon icon={faMapMarked} />
              </Button>
              <Button variant="dark" onClick={trackLocation} className={styles.mapButton}>
                <FontAwesomeIcon icon={faBullseye} />
              </Button>
            </React.Fragment>}
          </Col>
        </Row>
        {!formState.offline &&
        <React.Fragment>
        <WorldMap mapContainerClassName={[styles.worldMap, showMap && styles.worldMapVisible].join(' ')}
                   onClick={handleMapClick}
                   markers={worldMapMarkers()}
                   center={worldMapCenter()}/>
        <Row>
          <Col sm={4}>
            Location
          </Col>
          <Col xs={4} style={{paddingRight: 4}}>
            <FormInput name="latitude"
                       label=''
                       value={formState.latitude}
                       placeholder="latitude"
                       error={errors.latitude}
                       onChange={handleInputChange}/>
          </Col>
          <Col xs={4} style={{paddingLeft: 4}}>
            <FormInput name="longitude"
                       label=''
                       value={formState.longitude}
                       placeholder="longitude"
                       error={errors.longitude}
                       onChange={handleInputChange}/>
          </Col>
        </Row>
        {instrument() && instrument().requiredAttributes.map(attr =>
          <FormInput key={attr}
                     name={attr}
                     horizontal
                     label={uncamelize(attr)}
                     value={characteristics[attr]}
                     placeholder={characteristicsPlaceholder(attr)}
                     error={errors[attr]}
                     onChange={handleCharacteristicsChange}/>) }
        <h6>Sensors</h6>
        <hr/>
        {instrument() &&
        <InstrumentCalibrationModalSensors errors={errors}
                                           selectedSensors={selectedSensors}
                                           setSelectedSensors={setSelectedSensors}
                                           instrumentCalibrationSensors={instrumentCalibrationSensors}
                                           instrument={instrument()}
                                           />}
        </React.Fragment>}
      </Form>
    </ResourceModal>
  )
}