import Base from './Base'

class DownlinkPreset extends Base {
  static className = 'DownlinkPreset'
  static define() {
    this.attributes(
      'name', 'payload', 'port', 'deviceModel'
    )
  }
}

export default Base.createResource(DownlinkPreset)