import APILibrary from './APILibrary'

class Base extends APILibrary.Base {
  static createResource(klass){
    return APILibrary.createResource(klass)
  }

  canShow(){
    if (!this.persisted())
      return true
    return this.permissions && this.permissions.show
  }

  canUpdate(){
    if (!this.persisted())
      return true
    return this.permissions && this.permissions.update
  }

  canDestroy(){
    if (!this.persisted())
      return true
    return this.permissions && this.permissions.destroy
  }

  editableAttributes(){
    if (!this.constructor.__attributes)
      return []
    return this.constructor.__attributes.readWrite.toArray()
  }

  belongsToAssociations(){
    if (!this.__associations)
      return []
    return Object.keys(this.__associations).filter(assoc => this.__associations[assoc].reflection.macro === "belongsTo")
  }
}

export default Base