export const elementFullyInViewport = (el) => {
  if (!el) {
    return false
  }

  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  )
}

export const elementPartiallyInViewport = (el) => {
  if (!el) {
    return false
  }

  const rect = el.getBoundingClientRect()

  const height = (window.innerHeight || document.documentElement.clientHeight)
  const width = (window.innerWidth || document.documentElement.clientWidth)

  return (
    ((rect.top >= 0 && rect.top < height) || (rect.bottom >= 0 && rect.bottom < height)) &&
    ((rect.left >= 0 && rect.left < width) || (rect.right >= 0 && rect.right < width))
  )
}