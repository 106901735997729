import React, {useState} from 'react'
import { Button, Table } from 'react-bootstrap'
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import ResourceList from "./ResourceList"
import Time from "../lib/Time"

export default ({alarm}) => {
  const [ openEvents, setOpenEvents ] = useState([])

  const toggleEvent = (resource) => {
    if (openEvents.includes(resource.id))
      setOpenEvents(openEvents.filter(id => id !== resource.id))
    else
      setOpenEvents(openEvents.concat([resource.id]))
  }

  const baseQuery = () => {
    return alarm.instrumentAlarmEvents().includes('notifications.user')
  }

  const details = (resource) => {
    let details = ''
    switch(resource.cause){
      case 'value_off_range':
        details = resource.dataValuesWithRanges && resource.dataValuesWithRanges.map(v => (
          `${v.key}: ${v.value} (valid range: ${v.range})`
        )).join(', ')
        break
      case 'sensor_muted':
        details = <span>Last data <Time.Duration>{resource.dataAgeSecs * 1000}</Time.Duration> ago (limit: {resource.alarmProperties && resource.alarmProperties.dataAgeThresholdMins} minutes)</span>
        break
      default:
        details = ''
    }
    if (resource.commandNames) {
      details = `${details} - Commands sent: ${resource.commandNames.join(', ')}`
    }
    return details
  }

  return (
    <ResourceContext baseQuery={baseQuery()} defaultSort={{createdAt: 'desc'}}>
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>Logged at</th>
          <th>Cause</th>
          <th>Details</th>
          <th className="actions"></th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <React.Fragment key={resource.id}>
            <tr className="clickable" onClick={() => !resource.notifications().empty() && toggleEvent(resource)}>
              <td><Time>{resource.createdAt}</Time></td>
              <td>{resource.causeToHuman()}</td>
              <td>{details(resource)}</td>
              <td className="actions">
                {!resource.notifications().empty() &&
                <Button variant="link">
                  {!openEvents.includes(resource.id) &&
                  <FontAwesomeIcon icon={faChevronRight} />}
                  {openEvents.includes(resource.id) &&
                  <FontAwesomeIcon icon={faChevronDown} />}
                </Button>}
              </td>
            </tr>
            <tr style={{display: openEvents.includes(resource.id) ? 'table-row' : 'none'}}>
              <td colSpan={5}>
                <h6>Notifications</h6>
                {resource.notifications().toArray().map(notification => <dl className="row" key={notification.id + 'notification'}>
                  <dt className="col-auto">User:</dt>
                  <dd>{notification.user().name}</dd>
                  <dt className="col-auto">Sent at:</dt>
                  <dd>{notification.sentAt ? <Time>{notification.sentAt}</Time> : <span>-</span>}</dd>
                  <dt className="col-auto">Contact info:</dt>
                  <dd>{notification.contactInfo ? JSON.stringify(notification.contactInfo) : <span>-</span>}</dd>
                </dl>)}
              </td>
            </tr>
          </React.Fragment>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
    </ResourceContext>
  )
}