import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { RootContext } from '../contexts/RootContext'

export default ({ children, ...routeProps }) => {
  const { isAuthenticated } = useContext(RootContext)
  return (
    <Route
      {...routeProps}
      render={({ location }) => isAuthenticated() ? children : <Redirect to={{pathname: '/login', state: { from: location}}} />}
    />
  )
}