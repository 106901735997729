import React from "react"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

export default ({resource, variant, size, label, onClick}) => {
  return (
    <Button disabled={!resource.canUpdate()}
            variant={variant || 'link'} size={size || ''}
            title="Edit resource"
            onClick={onClick}>
      <FontAwesomeIcon icon={faEdit} />{label ? <span>&nbsp;Edit</span> : ''}
    </Button>
  )
}