import React, { useState } from "react"
import FormInput from "../lib/FormInput"
import {Col, Form, Row, Button} from "react-bootstrap"

export default ({handleInputChange, formState, setFormState, errors, accountMode}) => {
  const [showPassword, setShowPassword] = useState(false)

  const generatePassword = () => {
    const password = new Array(12).fill().map(() => String.fromCharCode(Math.random()*86+40)).join("")
    setFormState({...formState, password: password, passwordConfirmation: password})
  }

  return (
    <React.Fragment>
      <FormInput disabled={accountMode} name="name" value={formState.name} error={errors.name} onChange={handleInputChange} horizontal/>
      <FormInput name="email" value={formState.email} error={errors.email} onChange={handleInputChange} type="email" horizontal/>
      <FormInput name="phoneNumber" value={formState.phoneNumber} error={errors.phoneNumber} onChange={handleInputChange} horizontal/>
      <Form.Group  as={Row} controlId="notifications">
        <Form.Label column sm={4}>Notifications</Form.Label>
        <Col sm={8}>
          <Form.Check inline name="notifyEmail" type="checkbox" label="Email" checked={formState.notifyEmail || false} onChange={handleInputChange}/>
          <Form.Check inline name="notifySms" type="checkbox" label="SMS" checked={formState.notifySms || false} onChange={handleInputChange}/>
          <Form.Check inline name="notifyPush" type="checkbox" label="Push" checked={formState.notifyPush || false} onChange={handleInputChange} disabled={(formState.pushTokens || []).length === 0}/>
        </Col>
      </Form.Group>
      <FormInput name="password" value={formState.password} error={errors.password} onChange={handleInputChange} type={showPassword ? 'text' : 'password'} horizontal/>
      <FormInput name="passwordConfirmation" label="Password confirm" value={formState.passwordConfirmation} error={errors.passwordConfirmation} onChange={handleInputChange} type={showPassword ? 'text' : 'password'} horizontal/>
      <Form.Group  as={Row} controlId="notifications">
        <Col sm={4}></Col>
        <Col sm={8}>
          <Form.Check inline name="showPassword" type="checkbox" label="Show password" checked={showPassword} onChange={() => setShowPassword(!showPassword)}/>
          <Button size="sm" variant="secondary" onClick={generatePassword}>Generate</Button>
        </Col>
      </Form.Group>
    </React.Fragment>
  )
}