import React, {useContext, useEffect, useState} from 'react'
import {Button, Modal, ListGroup, Spinner, Form} from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import styles from "../styles/InstrumentDataModal.module.css"
import { RootContext } from "../contexts/RootContext"
import InstrumentDataDetails from "./InstrumentDataDetails"
import Breadcrumbs from "../lib/Breadcrumbs"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCog, faTimes, faCompress, faExpand} from "@fortawesome/free-solid-svg-icons"
import {InstrumentStatusContext} from "../contexts/InstrumentStatusContext"
import InstrumentWidgetRow from "./InstrumentWidgetRow"
import InstrumentBackground from "./InstrumentBackground"
import InstrumentBackgroundMarker from "./InstrumentBackgroundMarker"
import {FullScreen, useFullScreenHandle} from "react-full-screen"

export default ({show, onClose, instrument}) => {
  const { isAuthenticated } = useContext(RootContext)
  const [ currentInstrument, setCurrentInstrument ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const { addInstruments } = useContext(InstrumentStatusContext)
  const [ offsets, setOffsets ] = useState({})
  const [ saving, setSaving ] = useState(false)
  const fullscreenHandle = useFullScreenHandle()
  const [ showNames, setShowNames ] = useState(false)

  useEffect(
    () => {
      if (instrument)
        setCurrentInstrument(instrument)
      else
        setCurrentInstrument(null)
    },
    [instrument]
  )

  // Load children if not already loaded
  useEffect(() => {
    setOffsets({})
    if (currentInstrument && currentInstrument.group && currentInstrument.children().empty()){
      setLoading(true)
      currentInstrument.children().order({group: 'asc', name: 'asc'}).load().then(children => {
        children.each(c => c.assignParent(currentInstrument))
        addInstruments(children.toArray())
        setLoading(false)
      })
    }
  }, [currentInstrument])

  const group = () => (
    <ListGroup>
      {currentInstrument.children().toArray().map(child =>
      <ListGroup.Item action title={child.titleInfo()} onClick={() => setCurrentInstrument(child)} key={child.id}>
        <InstrumentWidgetRow instrument={child}
                             className={styles.instrumentRow}
                             iconClassName={styles.childIcon}
                             valueLabelClassName={styles.instrumentRowValueLabel}
                             key={child.id}/>
      </ListGroup.Item>
      )}
    </ListGroup>
  )

  const instrumentOffset = (instrument, index) => {
    if (offsets[instrument.id]) {
      return offsets[instrument.id]
    }
    if (instrument.uiSettings && instrument.uiSettings.markerOffset) {
      return instrument.uiSettings.markerOffset
    }
    return {
      x: index % 10 / 10 + 0.09,
      y: Math.floor(index / 10) / 10 + 0.15
    }
  }

  const handleOffsetChange = (instrumentId, offset) => {
    setOffsets({...offsets, [instrumentId]: offset})
  }

  const groupOnBackground = () => (
    <InstrumentBackground instrument={currentInstrument} onOffsetChange={handleOffsetChange}>
      {currentInstrument.children().toArray().map((child, index) =>
      <InstrumentBackgroundMarker
        showName={showNames}
        instrument={child}
        index={index}
        onClick={() => !hasUnsavedChanges() && setCurrentInstrument(child)}
        offset={instrumentOffset(child, index)}
        key={child.id}
      />)}
    </InstrumentBackground>
  )

  const details = () => (
    <React.Fragment>
      <InstrumentDataDetails instrument={currentInstrument}/>
    </React.Fragment>
  )

  const selectAncestor = (id) => {
    let parent = currentInstrument.parent()
    while(parent && parseInt(parent.id, 10) !== parseInt(id, 10)){
      parent = parent.parent()
    }
    if (parent && parseInt(parent.id, 10) === parseInt(id, 10)){
      setCurrentInstrument(parent)
    }
  }

  const hasUnsavedChanges = () => {
    return Object.keys(offsets).length > 0
  }

  const saveChanges = async () => {
    setSaving(true)
    try {
      for (const child of currentInstrument.children().toArray()) {
        const offset = offsets[child.id]
        if (offset) {
          // instrument latest data cant be sent to server, fails
          const latestData = child.instrumentLatestData().attributes()
          child.assignInstrumentLatestData(null)
          await child.update({uiSettings: {...child.uiSettings, markerOffset: offset}})
          child.buildInstrumentLatestData(latestData)
        }
      }
      setOffsets({})
    } catch(error) {
      console.error(error)
      alert('Saving changes failed!')
    }
    setSaving(false)
  }

  const cancelChanges = () => {
    setOffsets({})
  }

  const resolveDialogClassName = () => {
    if (currentInstrument && currentInstrument.hasBackground()) {
      return styles.backgroundModal
    }
    return styles.modalDialog
  }

  const Content = () => (
    <>
      {loading &&
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>}
      {currentInstrument &&
      <Breadcrumbs hover={currentInstrument && currentInstrument.hasBackground()} size="sm">
        {currentInstrument.ancestors.map(i =>
            <Button variant="link" onClick={() => {selectAncestor(i.id)}} key={i.id}>{i.name}</Button>)}
        {currentInstrument.name}
      </Breadcrumbs>}
      {currentInstrument &&
      currentInstrument.group &&
      !currentInstrument.hasBackground() &&
      group()}
      {currentInstrument &&
      currentInstrument.group &&
      currentInstrument.hasBackground() &&
      groupOnBackground()}
      {currentInstrument &&
      !currentInstrument.group &&
      details()}
    </>
  )

  return(
    <Modal
        show={show}
        onHide={onClose}
        dialogClassName={resolveDialogClassName()}
    >
      <Modal.Header closeButton>
        {currentInstrument &&
        <Modal.Title>
          {currentInstrument.icon() &&
          <img alt="icon" className={styles.icon} src={currentInstrument.icon()}/>}
          {currentInstrument.name}
        </Modal.Title>}
      </Modal.Header>
      <Modal.Body
          className={currentInstrument && currentInstrument.hasBackground() ? styles.backgroundModalBody : null}
      >
        <FullScreen handle={fullscreenHandle}>
          <Button variant="light" onClick={fullscreenHandle.exit} className="exit-full-screen-button hide-from-print">
            <FontAwesomeIcon icon={faCompress}/>
          </Button>
          {Content()}
        </FullScreen>
      </Modal.Body>
      <Modal.Footer>
        {currentInstrument && currentInstrument.hasBackground() &&
            <Form.Check
                style={{marginRight: 16}}
                name="show-names"
                type="checkbox"
                checked={showNames}
                onChange={() => setShowNames(!showNames)}
                label="Show names"
            />}
        {hasUnsavedChanges() &&
          <div style={{marginRight: 16}}>
            <Button variant="primary" onClick={saveChanges} disabled={saving} style={{marginRight: 10}}>
              <span>Save changes</span>
              {saving &&
              <Spinner animation="border" role="status" size="sm" style={{marginLeft: 10}}>
                <span className="sr-only">Loading...</span>
              </Spinner>}
            </Button>
            <Button variant="secondary" onClick={cancelChanges}>
              Cancel
            </Button>
          </div>}
        <Button variant="secondary" onClick={fullscreenHandle.enter}>
          <FontAwesomeIcon icon={faExpand}/>&nbsp;&nbsp;Full screen
        </Button>
        {currentInstrument && isAuthenticated && currentInstrument.canUpdate() &&
        <LinkContainer to={hasUnsavedChanges() ? '' : `/instruments/${currentInstrument.id}`}>
          <Button variant="secondary" disabled={hasUnsavedChanges()}>
            <FontAwesomeIcon icon={faCog}/>&nbsp;Admin
          </Button>
        </LinkContainer>}
        <Button variant="secondary" onClick={onClose} disabled={hasUnsavedChanges()}>
          <FontAwesomeIcon icon={faTimes}/>&nbsp;Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}