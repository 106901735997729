import Base from './Base'

class InstrumentClone extends Base {
  static className = 'InstrumentClone'
  static define() {
    this.belongsTo('instrument')
    this.belongsTo('organization')
    this.belongsTo('parent', { className: 'Instrument' })
    this.hasMany('userGroups')
    this.attributes(
      'name',
      'calibrate',
      'cloneTags',
      'cloneAlarms',
      'calibrationValidFrom',
      'sourceOfflineFrom'
    )
  }
}

export default Base.createResource(InstrumentClone)