import React, {useEffect, useState} from 'react'
import { Device, DownlinkPreset, Command } from '../Resources'
import FormSelect from "../lib/FormSelect"
import {Button, Card, ListGroup} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons"

export default ({formState, setFormState}) => {
  const [ devices, setDevices ] = useState([])
  const [ downlinkPresets, setDownlinkPresets ] = useState([])
  const [ selectedDevice, setSelectedDevice ] = useState(null)
  const [ selectedPreset, setSelectedPreset ] = useState(null)

  useEffect(() => {
    if (formState.organization)
      loadDevices()
  }, [formState.organization])

  useEffect(() => {
    if (selectedDevice === '-select-all-' && devices.length > 0) {
      loadPresets(devices.map(d => d.model))
    } else if (selectedDevice) {
      loadPresets(selectedDevice.model)
    } else {
      setDownlinkPresets([])
    }
  }, [selectedDevice])

  const loadDevices = () => {
    const conds = {'organization.id': formState.organization && formState.organization.id}
    Device.perPage(200).where(conds).order({id: 'asc'}).includes('instruments').all().then(res => setDevices(res.toArray()))
  }

  const loadPresets = (model) => {
    const conds = {'deviceModel': model}
    DownlinkPreset.perPage(200).where(conds).order({name: 'asc'}).all().then(res => setDownlinkPresets(res.toArray()))
  }

  const deviceOptionLabel = (device) => {
    const instruments = device.instruments().toArray()
    if (instruments.length === 0)
      return device.id
    else
      return `${device.id} (${instruments[0].fullName}${instruments.length > 1 ? '...' : ''})`
  }

  const deviceOptions = () => {
    return [['-select-all-', 'Select all devices'], ...devices.map(u => [u.id,  deviceOptionLabel(u)])]
  }

  const presetOptions = () => {
    return downlinkPresets.map(dp => [dp.id,  `${dp.name} (${dp.deviceModel})`])
  }

  const selectDevice = (ev) => {
    const value = ev.target.value
    if (value === '-select-all-') {
      setSelectedDevice(value)
    } else {
      const device = devices.find(obj => obj.id === value)
      setSelectedDevice(device)
    }
    setSelectedPreset(null)
  }

  const selectPreset = (ev) => {
    const value = ev.target.value
    const preset = downlinkPresets.find(obj => obj.id === value)
    setSelectedPreset(preset)
  }

  const addDownlinks = () => {
    const toAdd = []
    if (selectedDevice === '-select-all-') {
      for(const device of devices) {
        if (device.model.split('/')[0] === selectedPreset.deviceModel) {
          toAdd.push(
            {
              to: device.id,
              port: selectedPreset.port,
              payload: selectedPreset.payload,
              description: selectedPreset.name
            }
          )
        }
      }
    } else {
      toAdd.push({
        to: selectedDevice.id,
        port: selectedPreset.port,
        payload: selectedPreset.payload,
        description: selectedPreset.name
      })
    }
    const newDownlinks = toAdd.filter(dl1 =>
      !(formState.downlinks || []).find(dl2 =>
        dl1.to === dl2.to && dl1.payload === dl2.payload && dl1.port === dl2.port
      )
    )
    setFormState({...formState, downlinks: [...(formState.downlinks || []), ...newDownlinks]})
  }

  const removeDownlink = (downlink) => {
    setFormState({...formState, downlinks: formState.downlinks.filter(dl => dl !== downlink)})
  }

  const deviceInstrumentsLabel = (deviceId) => {
    const device = devices.find(obj => obj.id === deviceId)
    if (!device)
      return []
    const instruments = device.instruments().toArray()
    if (!instruments || instruments.length === 0)
      return ''
    return `${instruments[0].fullName}${instruments.length > 1 ? '...' : ''}`
  }

  return (
    <div>
      <ListGroup variant="flush">
      {formState.downlinks && formState.downlinks.map((downlink, index) =>
        <ListGroup.Item key={`dl-${index}`}>
          <strong>{downlink.description}</strong>
          &nbsp;
          <span>({downlink.payload}, port: {downlink.port})</span>
          &nbsp;to&nbsp;
          <strong>{downlink.to}</strong>
          <br/>
          <span style={{fontSize: '0.9em', color: '#666'}}>{deviceInstrumentsLabel(downlink.to)}</span>
          <Button className="float-right" variant="link-danger" size="sm" onClick={() => removeDownlink(downlink)}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </ListGroup.Item>)}
      </ListGroup>
      <Card>
        <Card.Body>
          <FormSelect
            name="deviceId"
            label=""
            value={selectedDevice && typeof selectedDevice === 'object' ? selectedDevice.id : selectedDevice}
            options={deviceOptions()}
            blank="- select device -"
            onChange={selectDevice}/>
          <FormSelect
            disabled={!selectedDevice}
            name="presetId"
            label=""
            value={selectedPreset && selectedPreset.id}
            options={presetOptions()}
            blank="- select preset -"
            onChange={selectPreset}/>
          <Button size="sm" variant="dark" onClick={addDownlinks} disabled={!selectedDevice || !selectedPreset}>
            <FontAwesomeIcon icon={faPlus} />&nbsp;
            {selectedDevice === '-select-all-' ? 'Add downlinks' : 'Add downlink'}
          </Button>
        </Card.Body>
      </Card>
    </div>
  )
}
