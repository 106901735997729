import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './styles/react-datetime.css'
import './fonts/Righteous-Regular.ttf'
import './images/space2.png'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App/>, document.getElementById('root'))

//document.body.style.backgroundImage = `linear-gradient(rgba(74,64,64,0.01),rgba(74,64,64,1)), url(${image})`
//document.body.style.backgroundImage = `linear-gradient(rgba(30,30,40,0.01),rgba(30,30,40,1)), url(${image})`

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
