import Base from './Base'

class Device extends Base {
  static className = 'Device'
  static define() {
    this.hasMany('instruments')
    this.attributes('model', 'createdAt', 'batteryLevel', 'batteryVoltage', 'spreadingFactor')
  }

  postDownlinkCmd(payload, port){
    const config = {
      method: 'POST',
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem('auth') || '{}').jwt}`
      },
      body: JSON.stringify({
        data: {
          type: 'device_downlinks',
          attributes: {payload, port}
        }
      })
    }

    const url = this.__links.downlinks

    return fetch(url, config).then(response => response.json().then(data => ({data, response}))).then(
      ({data, response}) => {
        if (!response.ok)
          return Promise.reject(response)
      }
    )
  }
}

export default Base.createResource(Device)