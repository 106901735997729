import React, {useContext} from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'
import { createUltimatePagination } from "react-ultimate-pagination"
import { ResourceContext } from "../contexts/ResourceContext"
import Url from "../lib/Url"
import queryString from 'query-string'
import {faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

//const UltimatePagination = reactUltimatePaginationBootstrap4

export default () => {
  const { page, setPage, resources } = useContext(ResourceContext)

  const pageCount = () => {
    if (resources && resources.__links && resources.__links.last){
      const params = queryString.parse(resources.__links.last.split('?')[1])
      return parseInt(params['page[number]'])
    } else
      return 1
  }

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber)
    Url.setQueryParam('page', pageNumber)
  }

  const PaginatorButton = ({ children, isActive, disabled, onClick, title }) => (
    <Button
      title={title}
      variant="dark"
      active={isActive}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  )

  const PaginatorWrapper = ({children}) => {
    return <ButtonGroup>{children}</ButtonGroup>
  }

  const Paginator = createUltimatePagination({
    WrapperComponent: PaginatorWrapper,
    itemTypeToComponent: {
      PAGE: (props) => <PaginatorButton {...props}>
        {props.value}
      </PaginatorButton>,
      ELLIPSIS: (props) => <PaginatorButton {...props}>
        ...
      </PaginatorButton>,
      FIRST_PAGE_LINK: ({onClick, disabled}) => <PaginatorButton title="First page" disabled={disabled} onClick={onClick}>
        <FontAwesomeIcon icon={faAngleDoubleLeft} />
      </PaginatorButton>,
      PREVIOUS_PAGE_LINK: ({onClick, disabled}) => <PaginatorButton title="Prev page" disabled={disabled} onClick={onClick}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </PaginatorButton>,
      NEXT_PAGE_LINK: ({onClick, disabled}) => <PaginatorButton title="Next page" disabled={disabled} onClick={onClick}>
        <FontAwesomeIcon icon={faAngleRight} />
      </PaginatorButton>,
      LAST_PAGE_LINK: ({onClick, disabled}) => <PaginatorButton title="Last page" disabled={disabled} onClick={onClick}>
        <FontAwesomeIcon icon={faAngleDoubleRight} />
      </PaginatorButton>
    }
  })

  return (
    <div className='resource-list-pagination-component'>
      {pageCount() > 1 &&
      <Paginator
        currentPage={Math.min(page || 1, pageCount())}
        totalPages={pageCount()}
        onChange={handlePageChange}
      />}
      <span className="total-record-count">
        {resources && resources.meta().recordCount || 0} rows total
      </span>
      <div style={{clear: 'both'}}></div>
    </div>
  )
}