import Base from './Base'

class InstrumentAlarmEvent extends Base {
  static className = 'InstrumentAlarmEvent'
  static define() {
    this.hasOne('instrumentAlarm')
    this.hasMany('notifications')
    this.attributes('cause', 'alarmProperties', 'dataAgeSecs', 'dataValues', 'createdAt', 'dataValuesWithRanges')
  }

  causeToHuman(){
    return {
      value_off_range: 'Value off range',
      sensor_muted: 'Muted sensor(s)',
      issues_cleared: 'Issues cleared',
      alarm_on: 'Alarm enabled',
      alarm_off: 'Alarm disabled',
    }[this.cause]
  }
}

export default Base.createResource(InstrumentAlarmEvent)