import Base from './Base'

class Notification extends Base {
  static className = 'Notification'
  static define() {
    this.belongsTo('user')
    //this.belongsTo('source', { polymorphic: true, inverseOf: 'notifications' })
    this.attributes('contactInfo', 'plainText', 'sentAt')
  }
}

export default Base.createResource(Notification)