import Base from './Base'

class OrganizationDevice extends Base {
  static className = 'OrganizationDevice'
  static define() {
    this.belongsTo('organization')
    this.attributes('name', 'remark', 'appEui', 'appKey', 'validFrom')
  }
}

export default Base.createResource(OrganizationDevice)