import React, {useEffect, useRef} from "react"
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import FormGroup from "./FormGroup"

//NOTE! options format: [{id: <id>, label: <label>},...]

const FormTypeahead = ({
  name,label, value, onChange, onInputChange, onPaginate, error,
  allowNew, multiple, disabled, placeholder, options, clearButton, horizontal
}) => {
  const typeaheadRef = useRef(null)
  const typeaheadTimeoutRef = useRef(null)

  useEffect(() => {
    return () => {
      if (typeaheadTimeoutRef.current)
        clearTimeout(typeaheadTimeoutRef.current)
    }
  }, [])

  const handleChange = (vals) => {
    let values = vals.map(v => {
      if (v && v.customOption)
        return v.label
      else
        return v
    })

    if (!multiple){
      if (values.length > 0)
        values = values[0]
      else
        values = ''
    }

    onChange({target: {name: name, value: values}})
  }

  const handleInputChange = (value) => {
    if (onInputChange === null)
      return
    if (typeaheadTimeoutRef.current)
      window.clearTimeout(typeaheadTimeoutRef.current)
    typeaheadTimeoutRef.current = window.setTimeout(() => onInputChange(value), 500)
  }

  const selected = () => {
    if (Array.isArray(value))
      return value
    else if (value)
      return [value]
    else
      return []
  }

  const onBlur = (event) => {
    if (!allowNew)
      return
    const val = event.target.value
    if (!val) return
    if (!multiple) {
      onChange({target: {name: name, value: val}})
    } else {
      typeaheadRef.current.getInstance().clear()
      onChange({target: {name: name, value: [...value, val]}})
    }
  }

  return (
    <FormGroup name={name} label={label} horizontal={horizontal}>
      <Typeahead ref={typeaheadRef}
                 className={error ? 'is-invalid' : ''}
                 onBlur={onBlur}
                 allowNew={allowNew}
                 multiple={multiple}
                 id={`typeahead-for-${name}`}
                 clearButton={clearButton}
                 selected={selected()}
                 onInputChange={handleInputChange}
                 onChange={handleChange}
                 paginate
                 onPaginate={onPaginate}
                 disabled={disabled}
                 placeholder={placeholder}
                 options={options}
                 maxResults={25}
      />
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </FormGroup>
  )
}

FormTypeahead.propTypes = {
  resourceForm: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  allowNew: PropTypes.bool,
  clearButton: PropTypes.bool,
  horizontal: PropTypes.any
}

FormTypeahead.defaultProps = {
  disabled: false,
  placeholder: null,
  onInputChange: null,
  onChange: null,
  multiple: false,
  allowNew: false,
  clearButton: false,
  horizontal: false
}

export default FormTypeahead