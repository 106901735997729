import React, {useContext, useEffect, useState} from "react"
import {Alert} from "react-bootstrap"
import {RootContext} from "../contexts/RootContext"
import styles from '../styles/AlertMessage.module.css'

export default () => {
  const { message, clearMessage  } = useContext(RootContext)
  const [ text, setText ] = useState("")
  const [ type, setType ] = useState("")
  const [ show, setShow ] = useState(false)

  useEffect(() => {
    if (message){
      setText(message.text)
      setType(message.type)
      setShow(true)
    } else {
      setShow(false)
    }
  }, [message])

  return (
    <div className={[styles.alert, show && styles.alertVisible].join(' ')}>
      <Alert variant={type} onClose={clearMessage} dismissible>
        {text}
      </Alert>
    </div>
  )
}
