import Base from './Base'

class InstrumentAlarmCommand extends Base {
  static className = 'InstrumentAlarmCommand'
  static define() {
    this.hasOne('instrumentAlarm')
    this.hasOne('command')
    this.attributes('cause')
  }
}

export default Base.createResource(InstrumentAlarmCommand)