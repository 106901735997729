import React, {useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import User from '../resources/User'
import UserInputs from "./UserInputs"

export default ({userId, show, onClose, afterSave}) => {
  const [ formState, setFormState ] = useState({})
  const [ errors, setErrors ] = useState({})
  const [ user, setUser ] = useState(null)

  useEffect(
    () => {
      if (userId){
        User.find(userId).then(result => {
          //console.log(result)
          setUser(result)
          setFormState({
            name: result.name,
            email: result.email,
            phoneNumber: result.phoneNumber,
            notifyEmail: result.notifyEmail,
            notifySms: result.notifySms,
            notifyPush: result.notifyPush,
            pushTokens: result.pushTokens
          })
        })
      }
      setErrors({})
    },
    [userId]
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox')
      value = ev.target.checked
    setFormState({...formState, [name]: value})
  }

  const save = () => {
    user.assignAttributes(formState)
    return user.save().then(() => {
      setErrors({})
      onClose()
      afterSave({name: user.name, email: user.email})
    }).catch(err => {
      let errors = {}
      user.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
        <UserInputs
          accountMode
          setFormState={setFormState}
          formState={formState}
          errors={errors}
          handleInputChange={handleInputChange}
        />
      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={save}>
          <span>Update</span>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}