import React, {useContext, useEffect, useState} from 'react'
import {OverlayTrigger, Tooltip, Button, Row, Col, Spinner, Container} from "react-bootstrap"
import Time from "../lib/Time"
import Instrument from "../resources/Instrument"
import InstrumentDatum from "../resources/InstrumentDatum"
import styles from "../styles/InstrumentDataModal.module.css"
import InstrumentDataChart from "./InstrumentDataChart"
import {faExclamationTriangle, faEyeSlash, faHistory, faMapMarked} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import moment from "moment-timezone"
import config from '../config'
import InstrumentDataMap from "./InstrumentDataMap"
import InstrumentDataFileLoader from "./InstrumentDataFileLoader"
import {InstrumentStatusContext} from "../contexts/InstrumentStatusContext"
import InstrumentDataTimeFilter from "./InstrumentDataTimeFilter"

export default ({instrument}) => {
  const [ data, setData ] = useState([])
  const [ requestedDataMode, setRequestedDataMode ] = useState(null)
  const [ dataMode, setDataMode ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const [ alarmLoading, setAlarmLoading ] = useState(false)
  const [ fromTime, setFromTime ] = useState(moment().startOf('day'))
  const [ toTime, setToTime ] = useState(moment().endOf('day'))
  const [ showMap, setShowMap ] = useState(false)
  const { lastUpdateTime, lastDataTime } = useContext(InstrumentStatusContext)

  useEffect(
    () => {
      if (instrument){
        setAlarmLoading(true)
        instrument.loadInstrumentAlarm().then(() => setAlarmLoading(false))
      }
    }, [instrument]
  )

  useEffect(
    () => {
      loadData()
    }, [instrument, requestedDataMode, fromTime, toTime]
  )

  // Load new data if current moment fits into selected chart time range
  useEffect(
    () => {
      if (toTime.unix() > moment().unix()){
        loadData()
      }
    }, [lastDataTime(instrument && instrument.id)]
  )

  const loadData = () => {
    const instrumentData = new InstrumentDatum(instrument)
    setLoading(true)
    instrumentData.dataForChart(fromTime, toTime, requestedDataMode).then(d => {
      setLoading(false)
      setData(d)
      setDataMode(requestedDataMode)
    })
  }

  const latestData = () => {
    if (!instrument || !instrument.instrumentLatestData || !instrument.instrumentLatestData())
      return {values: {}, time: null, lon: null, lat: null}
    else
      return instrument.instrumentLatestData()
  }

  const mapShouldBeVisible = () => {
    return instrument.isTracker() || showMap
  }

  const chartUnitPostfix = () => {
    if (dataMode === 'hourAvg')
      return ' (Hourly avg)'
    else if (dataMode === 'quarterDayAvg')
      return ' (3h avg)'
    else if (dataMode === 'dayAvg')
      return ' (Daily avg)'
    else
      return ''
  }

  const chartSpan = () => {
    if (toTime.valueOf() < fromTime.valueOf())
      return 0
    let hours = Math.ceil(toTime.diff(fromTime, 'hours')) + 1
    if (hours > 24 * 3)
      return Math.ceil(toTime.diff(fromTime, 'days')) + 1
    else
      return hours
  }

  const chartResolution = () => {
    let hours = Math.ceil(toTime.diff(fromTime, 'hours')) + 1
    if (hours > 24 * 3)
      return 'day'
    else
      return 'hour'
  }

  const alarmLimits = () => {
    if (instrument && instrument.instrumentAlarm() && instrument.instrumentAlarm().disabledAt == null && !alarmLoading)
      return instrument.instrumentAlarm().dataValueRanges
    else
      return {}
  }

  const Options = () => (
    <Container fluid>
      <Row noGutters>
        <InstrumentDataTimeFilter setRequestedDataMode={setRequestedDataMode}
                                  requestedDataMode={requestedDataMode}
                                  fromTime={fromTime}
                                  toTime={toTime}
                                  setFromTime={setFromTime}
                                  setToTime={setToTime}/>
        <Col xs="auto" className={[styles.timeFilterItem, "hide-from-print"].join(' ')}>
          <InstrumentDataFileLoader instrument={instrument} from={fromTime} to={toTime} disabled={false}/>
        </Col>
        {latestData().values && latestData().values[0] &&
        <Col xs="auto" className={[styles.timeFilterItem, "hide-from-print"].join(' ')}>
          <Button size="sm" variant="dark" onClick={() => setShowMap(!showMap)} title="Toggle map">
            <FontAwesomeIcon icon={faMapMarked} />
          </Button>
        </Col>}
        <Col xs="auto" className={styles.timeFilterItem}>
          {loading &&
          <Spinner size="sm" animation="border" role="status" className={styles.spinner}>
            <span className="sr-only">Loading...</span>
          </Spinner>}
        </Col>
      </Row>
    </Container>
  )

  const LatestValues = () => (
    <Row noGutters>
      {instrument.latestData().map(item => item.value !== null &&
        <Col key={item.key}>
          <div className={styles.attribute}  style={{opacity: instrument.latestDataIsTooOld() ? 0.5 : 1}}>
            <span className={styles.label} style={{color: Instrument.color(item.key)}}>
              {item.label}
            </span>
            <span className={styles.value} style={{color: Instrument.color(item.key)}}>
              {item.value}<span className={styles.unit}>&nbsp;{item.unit}</span>
            </span>
            {instrument.rangeAlarmIsTriggered(item.key) &&
            <OverlayTrigger overlay={<Tooltip>Valid range:<br/>{instrument.validRangeLabel(item.key)}</Tooltip>}>
              <FontAwesomeIcon className={styles.rangeWarning} icon={faExclamationTriangle} />
            </OverlayTrigger>}
          </div>
        </Col>
      )}
      <Col className={styles.details}>
        <strong>Measured at</strong>
        <Time>{latestData().time}</Time>
        {instrument.isOffline() && <FontAwesomeIcon icon={faEyeSlash} className={styles.offlineIcon} title="Instrument has been taken offline"/>}
        {!instrument.isOffline() && instrument.latestDataIsTooOld() && <FontAwesomeIcon icon={faHistory} className={styles.oldDataIcon} title="Latest data might be outdated!"/>}
        {!instrument.isOffline() && instrument.dataAgeAlarmIsTriggered() &&
        <OverlayTrigger overlay={<Tooltip>Last data received:<br/>
          <Time.DurationToNow>{latestData().time}</Time.DurationToNow> ago<br/>
          Max valid delay:<br/>
          <Time.Duration>{instrument.instrumentAlarm() && instrument.instrumentAlarm().dataAgeThresholdMins * 60000}</Time.Duration></Tooltip>}>
          <FontAwesomeIcon className={styles.rangeWarning} icon={faExclamationTriangle} />
        </OverlayTrigger>}
        <br/>
        <strong>Location</strong>
        {instrument.latestLocation() ? `${instrument.latestLocation().lat}, ${instrument.latestLocation().lon}` : '-'} (DD)
      </Col>
    </Row>
  )

  return(
    <React.Fragment>
      <Container fluid>
        <span style={{display: 'none'}}>{instrument && lastUpdateTime(instrument.id)}</span>
        {LatestValues()}
      </Container>
      <hr/>
      {Options()}
      {data && data.data && data.data.length >= config.maxDataBatchSize &&
      <span className={styles.maxResultReached}>Maximum result size of {config.maxDataBatchSize} samples reached!</span>}
      {data && data.data && <InstrumentDataMap data={data.data}
                                  visible={mapShouldBeVisible()}
                                  className={[styles.mapContainer, mapShouldBeVisible() && styles.mapContainerVisible].join(' ')}/>}
      {instrument && data && data.data && latestData().values && latestData().values[0] &&
      <InstrumentDataChart data={data.data}
                           totals={data.totals}
                           instrument={instrument}
                           date={toTime}
                           alarmLimits={alarmLimits()}
                           unitPostfix={chartUnitPostfix()}
                           height={300}
                           span={chartSpan()}
                           resolution={chartResolution()}/>}
    </React.Fragment>
  )
}