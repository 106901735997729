import Base from './Base'

class DeviceConfig extends Base {
  static className = 'DeviceConfig'

  static define() {
    this.attributes('deviceName', 'description', 'config')
  }
}

export default Base.createResource(DeviceConfig)
