import React, {useEffect, useState} from "react"
import Time from "../lib/Time"
import styles from '../styles/OrganizationReports.module.css'
import {Spinner} from "react-bootstrap"

export default ({organization}) => {
  const [deviceActivityReportLoading, setDeviceActivityReportLoading] = useState(false)
  const [deviceActivityReport, setDeviceActivityReport] = useState(null)

  useEffect(() => {
    setDeviceActivityReportLoading(true)
    organization.loadDeviceActivityReport().then(report => {
      setDeviceActivityReport(report)
    }).finally(() => {
      setDeviceActivityReportLoading(false)
    })
  }, [organization])

  const parseDate = (date) => {
    const year = date.substr(0, 4)
    const month = date.substring(4)
    return new Date(year, month, 0)
  }

  const deviceActivityReportItems = () => (
    [...Object.entries(deviceActivityReport.report).sort((a, b) => (
      parseDate(a[0]).getTime() - parseDate(b[0]).getTime()
    )), [
      'Total',
      Object.entries(deviceActivityReport.report).reduce((sum, [date, value]) => sum + value, 0)
    ]]
  )

  return (
    <div>
      <p></p>
      <h5>Device activity per month</h5>
      {deviceActivityReportLoading &&
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>}
      <div className={styles.deviceActivityReport}>
        {deviceActivityReport && deviceActivityReportItems().map(([date, value], index) => (
          <div key={date} className={styles.deviceActivityReportItem}>
            <div className={styles.deviceActivityReportItemDate}>
              {date !== 'Total' &&
              <Time timeFormat={null} dateFormat="months">{parseDate(date)}</Time>}
              {date === 'Total' &&
              <span>{date}</span>}
            </div>
            <div className={index % 2 === 0 ? styles.deviceActivityReportItemValue : styles.deviceActivityReportItemValueOdd}>
              {value}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}