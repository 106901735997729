import React, {useState, useContext, useEffect} from 'react'
import {Form} from 'react-bootstrap'
import FormInput from '../lib/FormInput'
import ResourceModal from "./ResourceModal"
import {RootContext} from "../contexts/RootContext"
import {DownlinkPreset} from "../Resources"
import FormSelect from "../lib/FormSelect"

export default ({resource, show, onClose}) => {
  const [ formState, setFormState ] = useState({})
  const [ errors, setErrors ] = useState({})
  const [ presets, setPresets ] = useState([])
  const [ currentPreset, setCurrentPreset ] = useState(0)
  const { showMessage, auth } = useContext(RootContext)

  useEffect(
    () => {
      setFormState({port: 1})
      setErrors({})
      if (resource){
        DownlinkPreset.where({deviceModel: resource.model}).perPage(1000).all().then(r => setPresets(r.toArray()))
      } else {
        setPresets([])
        setCurrentPreset(0)
      }
    },
    [resource]
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    const value = ev.target.value
    setFormState({...formState, [name]: value})
    setCurrentPreset(0)
  }

  const handlePresetChange = (ev) => {
    const preset = presets.find(p => parseInt(p.id, 10) === parseInt(ev.target.value, 10))
    if (preset)
      setFormState({...formState, payload: preset.payload, port: preset.port})
    setCurrentPreset(preset.id)
  }

  const save = () => {
    if (!formState.payload || formState.payload === '')
      setErrors({payload: 'must be given'})
    else if (!formState.port || formState.port === '')
      setErrors({port: 'must be given'})
    else
      return resource.postDownlinkCmd(formState.payload, formState.port).then(() => {
        showMessage("Downlink command sent")
        onClose()
      }).catch(err => {
        showMessage("Downlink command sending failed", "danger")
        console.error(err)
        onClose()
      })
  }

  return (
    <ResourceModal show={show} onClose={onClose} onSubmit={save} title="Downlink" submitLabel="Send command">
      <Form>
        <FormInput name="devEUI" value={resource && resource.id} disabled/>
        <FormSelect
          name="preset"
          label="Preset"
          value={currentPreset}
          options={[[0,  '- choose -']].concat(presets.map(p => [p.id, p.name]))}
          onChange={handlePresetChange}/>
        <FormInput disabled={!auth.user.admin}
                   name="payload"
                   value={formState.payload}
                   error={errors.payload}
                   onChange={handleInputChange}/>
        <FormInput disabled={!auth.user.admin}
                   name="port"
                   value={formState.port}
                   error={errors.port}
                   onChange={handleInputChange}/>
      </Form>
    </ResourceModal>
  )
}