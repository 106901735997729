import React from 'react'
import {Col, Row, Button} from "react-bootstrap"
import FormInput from "../lib/FormInput"
import FormSelect from "../lib/FormSelect"
import styles from '../styles/InstrumentAlarmModal.module.css'

export default ({formState, setFormState, resource, setRangesAreValid}) => {
  const handleInputChange = (index, key, value) => {
    const triggers = [...(formState.offRangeTriggers || [])]
    if (key === 'attribute' && !value) {
      triggers[index]['attribute'] = null
      triggers[index]['min'] = null
      triggers[index]['max'] = null
      triggers[index]['dayOfWeek'] = null
      triggers[index]['timeFrom'] = null
      triggers[index]['timeTo'] = null
    } else {
      triggers[index][key] = value
    }
    setFormState({...formState, offRangeTriggers: triggers})
    setRangesAreValid(!isInvalid())
  }

  const triggerValue = (i, key) => {
    if (!formState.offRangeTriggers)
      return null
    if (formState.offRangeTriggers[i] &&
      formState.offRangeTriggers[i][key] !== null &&
      formState.offRangeTriggers[i][key] !== undefined &&
      formState.offRangeTriggers[i][key] !== '')
      return formState.offRangeTriggers[i][key]
    return null
  }

  const valueRangeIsValid = (i) => {
    if (!formState.offRangeTriggers[i].attribute)
      return true
    else if (triggerValue(i, 'min') === null && triggerValue(i, 'max') === null)
      return false
    else if (triggerValue(i, 'max') !== null && isNaN(triggerValue(i, 'max')))
      return false
    else if (triggerValue(i, 'min') !== null && isNaN(triggerValue(i, 'min')))
      return false
    else if (triggerValue(i, 'min') === null || triggerValue(i, 'max') === null)
      return true
    return parseFloat(triggerValue(i, 'min')) <= parseFloat(triggerValue(i, 'max'))
  }

  const timeRangeIsValid = (i) => {
    if (!formState.offRangeTriggers[i].attribute)
      return true
    else if (triggerValue(i, 'timeFrom') !== null &&
             triggerValue(i, 'timeTo') !== null &&
             parseInt(triggerValue(i, 'timeFrom').replace(':','')) >= parseInt(triggerValue(i, 'timeTo').replace(':','')))
      return false
    return true
  }

  const isInvalid = () => {
    if (formState.offRangeTriggers.some((t, i) => !valueRangeIsValid(i) || !timeRangeIsValid(i)))
      return true
    return false
  }

  const attributeOptions = () => (
    resource && resource.instrument().dataAttributes.map(da => [da.key, da.key]) || []
  )

  const dayOfWeekOptions = () => (
    [[1, 'Mon'], [2, 'Tue'], [3, 'Wed'], [4, 'Thu'], [5, 'Fri'], [6, 'Sat'], [7, 'Sun']]
  )

  const hourOptions = () => (
    [...Array(25)].map((_n, i) => [`${i}:00`, `${i}:00`])
  )

  const canAddTrigger = () => {
    if (!formState.offRangeTriggers || formState.offRangeTriggers.length === 0)
      return true
    return formState.offRangeTriggers[formState.offRangeTriggers.length - 1].attribute !== null &&
      formState.offRangeTriggers[formState.offRangeTriggers.length - 1].attribute !== '' &&
      !isInvalid()
  }

  const addTrigger = () => {
    let triggers = [...(formState.offRangeTriggers || []).map(t => Object.assign({}, t))]
    triggers.push({attribute: null})
    setFormState({...formState, offRangeTriggers: triggers})
  }

  return (
    <React.Fragment>
      {formState.offRangeTriggers && formState.offRangeTriggers.map((trigger, i) =>
      <Row key={i} className={styles.offRangeTrigger} noGutters>
        <Col xs={3}>
          <FormSelect name={`attribute`}
                      size={"sm"}
                      label={i === 0 ? "Attribute" : ""}
                      value={trigger.attribute}
                      onChange={(ev) => handleInputChange(i, 'attribute', ev.target.value)}
                      options={[['', '- none -'], ...attributeOptions()]}/>
        </Col>
        <Col>
          <FormInput name={`min`}
                     size={"sm"}
                     label={i === 0 ? "Min" : ""}
                     placeholder="-"
                     disabled={!trigger.attribute}
                     error={!valueRangeIsValid(i)}
                     value={trigger.min}
                     onChange={(ev) => handleInputChange(i, 'min', ev.target.value)}/>
        </Col>
        <Col>
          <FormInput name={`max`}
                     size={"sm"}
                     label={i === 0 ? "Max" : ""}
                     placeholder="-"
                     disabled={!trigger.attribute}
                     error={!valueRangeIsValid(i)}
                     value={trigger.max}
                     onChange={(ev) => handleInputChange(i, 'max', ev.target.value)}/>
        </Col>
        <Col>
          <FormSelect name={`dayOfWeek`}
                      size={"sm"}
                      label={i === 0 ? "Weekday" : ""}
                      disabled={!trigger.attribute}
                      value={parseInt(trigger.dayOfWeek, 10)}
                      onChange={(ev) => handleInputChange(i, 'dayOfWeek', ev.target.value)}
                      options={[['', '- any -'], ...dayOfWeekOptions()]}/>
        </Col>
        <Col>
          <FormSelect name={`timeFrom`}
                      size={"sm"}
                      label={i === 0 ? "From" : ""}
                      disabled={!trigger.attribute}
                      error={!timeRangeIsValid(i)}
                      value={trigger.timeFrom}
                      onChange={(ev) => handleInputChange(i, 'timeFrom', ev.target.value)}
                      options={[['', '-'], ...hourOptions()]}/>
        </Col>
        <Col>
          <FormSelect name={`timeTo`}
                      size={"sm"}
                      label={i === 0 ? "To" : ""}
                      disabled={!trigger.attribute}
                      error={!timeRangeIsValid(i)}
                      value={trigger.timeTo}
                      onChange={(ev) => handleInputChange(i, 'timeTo', ev.target.value)}
                      options={[['', '-'], ...hourOptions()]}/>
        </Col>
      </Row>)}
      <Button disabled={!canAddTrigger()} variant="dark" size="sm" style={{float: 'right'}} onClick={addTrigger}>
        + Add trigger
      </Button>
      <div style={{clear: 'both', height: '0px'}}>&nbsp;</div>
    </React.Fragment>
  )
}
