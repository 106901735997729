import Base from './Base'

class InstrumentLatestDatum extends Base {
  static className = 'InstrumentLatestDatum'
  static define() {
    this.hasOne('instrument')
    this.attributes(
      'time',
      'values',
      'longitude',
      'latitude',
      'hasExpired',
      'outdatedAt',
      'spreadingFactor',
      'offRangeAttributes',
      'priorities',
      'avgIntervalSec',
      'batteryLevel',
      'batteryVoltage'
    )
  }

  age(){
    if (!this.time)
      return 0
    return Date.now() - Date.parse(this.time)
  }
}

export default Base.createResource(InstrumentLatestDatum)