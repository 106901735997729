class WebSocketWrapper{

  constructor(params){
    this.ws = null
    this.url = params.url
    this.attempts = 0
    this.protocol = params.protocol
    this.reconnectIntervalInMilliSeconds = params.reconnectIntervalInMilliSeconds || 0
    this.reconnect = params.reconnect === undefined ? true : params.reconnect
    this.debug = params.debug
    this.onOpen = params.onOpen
    this.onError = params.onError
    this.onMessage = params.onMessage
    this.onClose = params.onClose
    this.reconnecting = false
    this._setup()
    // Sometimes WebSocket closes without firing callbacks, this interval should be able to resurrect from that
    if (this.reconnect)
      this.checkHandle = setInterval(this.checkWebSocketConnection.bind(this), 30000)
  }

  log(line){
    if (!this.debug)
      return
    console.log(`${new Date().toJSON()} - ${line}`)
  }

  checkWebSocketConnection(){
    if (this._isClosedForGood()){
      this.log("Resurrecting totally dead WebSocket connection...")
      this._setup()
    }
  }

  _isClosedForGood(){
    if (this.reconnecting)
      return false
    return !this.ws ||
      !this.ws.readyState ||
      this.ws.readyState === WebSocket.CLOSED ||
      this.ws.readyState === WebSocket.CLOSING
  }

  _generateInterval(k){
    if(this.reconnectIntervalInMilliSeconds > 0)
      return this.reconnectIntervalInMilliSeconds
    return Math.min(30, (Math.pow(2, k) - 1)) * 1000
  }

  _setup(){
    let websocket = null
    this.log(`Connecting via WebSocketWrapper (attempt ${this.attempts})...`)
    try {
      websocket = new WebSocket(this.url, this.protocol)
    } catch (err) {
      this.log('Unable to initialize WebSocket')
      if (this.onError)
        this.onError(err)
    }

   this.ws = websocket

    websocket.onopen = () => {
      if (this.reconnectHandle)
        clearTimeout(this.reconnectHandle)
      this.reconnecting = false
      this.attempts = 0
      this.log('Websocket connected: ' + this.url)
      if (this.onOpen)
        this.onOpen()
    }

    websocket.onmessage = (evt) => {
      this.onMessage(evt.data)
    }

    websocket.onerror = (evt) => {
      this.log('Websocket ERROR: ' + evt)
      if (this.onError)
        this.onError(evt)
    }

    websocket.onclose = (evt) => {
      this.log('Websocket disconnected: ' + evt.code)
      if (this.onClose)
        this.onClose(evt, this.attempts)
      if (this.reconnect) {
        this.reconnecting = true
        let time = this._generateInterval(this.attempts)
        this.reconnectHandle = setTimeout(() => {
          this.attempts += 1
          this._setup()
        }, time)
      }
    }
  }

  sendData(data){
    if (!this.ws)
      return
    const msg = JSON.stringify(data)
    this.log("Sending ws data: " + msg)
    this.ws.send(msg)
  }

  close() {
    this.log('Closing via WebSocketWrapper...')
    this.reconnect = false
    if (this.ws)
      this.ws.close()
    if (this.checkHandle)
      clearInterval(this.checkHandle)
    if (this.reconnectHandle)
      clearTimeout(this.reconnectHandle)
  }
}

export default WebSocketWrapper