import React, {useContext, useEffect, useState} from 'react'
import FormInput from '../lib/FormInput'
import FormSelect from '../lib/FormSelect'
import Organization from "../resources/Organization"
import UserGroupModalRoles from "./UserGroupModalRoles"
import ResourceModal from "./ResourceModal"
import {RootContext} from "../contexts/RootContext"
import {ResourceContext} from "../contexts/ResourceContext"

export default ({resource, show, onClose}) => {
  const [ organizations, setOrganizations ] = useState([])
  const { canCreate } = useContext(RootContext)
  const [ formState, setFormState ] = useState({})
  const { reloadResources } = useContext(ResourceContext)
  const [ errors, setErrors ] = useState({})
  const [ roles, setRoles ] = useState([])

  useEffect(
    () => {
      Organization.perPage(1000).all().then(orgs => setOrganizations(orgs.toArray()))
    },
    []
  )

  useEffect(
    () => {
      setErrors({})
      if (resource){
        let initialState = {}
        resource.editableAttributes().forEach(attr => initialState[attr] = resource[attr])
        initialState.organization = resource.organization()
        setFormState(initialState)
        setRoles(resource.roles().toArray().sort((a, b) => (a.name > b.name) - (a.name < b.name)).map(r => ({
          resource: r,
          state: {roleType: r.roleType}
        })))
      }
    },
    [resource]
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox')
      value = ev.target.checked
    setFormState({...formState, [name]: value})
  }

  const save = () => {
    resource.assignAttributes(formState)
    const msg = resource.persisted() ? 'User group updated' : 'User group created'
    return resource.save().then(async () => {
      await deleteRoles()
      await upsertRoles()
      reloadResources(msg)
      onClose()
    }).catch(err => {
      let errors = {}
      console.log("Error saving the user group: " + err)
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  const deleteRoles = async () => {
    for(let role of resource.roles().toArray()){
      if (!roles.map(r => r.resource).includes(role) && role.canDestroy())
        await role.destroy()
    }
  }

  const upsertRoles = async () => {
    for(let role of roles){
      if ((role.resource.persisted() && role.resource.canUpdate()) ||
        (!role.resource.persisted() && canCreate('roles')))
      {
        role.resource.assignAttributes(role.state)
        await role.resource.save()
      }
    }
  }

  return (
    <ResourceModal show={show} onClose={onClose} onSubmit={save} resource={resource} label="user group">
      <FormSelect
        disabled
        name="organization"
        label="Organization"
        options={organizations.map(org => [org.id, org.name])}
        value={formState.organization && formState.organization.id}
        error={errors.organization}
        onChange={handleInputChange}/>
      <FormInput name="name" value={formState.name} error={errors.name} onChange={handleInputChange}/>
      <h5>Roles</h5>
      <UserGroupModalRoles
        userGroup={resource}
        roles={roles}
        setRoles={setRoles}/>
    </ResourceModal>
  )
}