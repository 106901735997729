import React, { useContext } from "react"
import {Button} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlus} from "@fortawesome/free-solid-svg-icons"
import {RootContext} from "../contexts/RootContext"
import {ResourceContext} from "../contexts/ResourceContext"

export default ({children, onClick, endpoint}) => {
  const { canCreate } = useContext(RootContext)
  const { resources } = useContext(ResourceContext)

  const handleClick = (ev) => {
    onClick(ev, resources)
  }

  return (
    <Button
      variant="dark"
      onClick={handleClick}
      disabled={!canCreate(endpoint)}>
      <FontAwesomeIcon icon={faPlus} />&nbsp;
      <span className="hide-for-sm">
      {children}
      </span>
    </Button>
  )
}