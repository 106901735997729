import {Button, ButtonGroup, Dropdown} from "react-bootstrap"
import {LinkContainer} from "react-router-bootstrap"
import React, {useEffect, useState} from "react"
import {useHistory} from "react-router-dom"
import styles from '../styles/SubMenu.module.css'

export default ({baseUrl, items}) => {
  const [ active, setActive ] = useState('home')
  const history = useHistory()

  useEffect(
    () => {
      resolveActive(window.location)
      return history.listen(location => resolveActive(location))
    }, [history]
  )

  const enabledItems = () => (
    items.filter(i => i)
  )

  const resolveActive = (location) => {
    const current = enabledItems().find(item => location.pathname.includes(item.url))
    if (current)
      setActive(current.label)
    else if (enabledItems().length > 0)
      setActive(enabledItems()[0].label)
    else
      setActive(null)
  }

  return(
    <React.Fragment>
      <ButtonGroup className={styles.btnGroup}>
        {enabledItems().map(item => (
          <LinkContainer to={`${baseUrl || ''}${item.url}`} key={item.url}>
            <Button variant="dark">{item.label}</Button>
          </LinkContainer>
        ))}
      </ButtonGroup>
      <Dropdown className={styles.dropdown}>
        <Dropdown.Toggle variant="dark" className="active">{active}</Dropdown.Toggle>
        <Dropdown.Menu>
        {enabledItems().map(item => (
          <LinkContainer to={`${baseUrl || ''}${item.url}`} key={item.url}>
          <Dropdown.Item>
            {item.label}
          </Dropdown.Item>
          </LinkContainer>
        ))}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  )
}