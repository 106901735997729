import Base from './Base'

class Role extends Base {
  static className = 'Role'
  static define() {
    this.belongsTo('user')
    this.belongsTo('userGroup')
    this.attributes(
      'roleType'
    )
  }
  static TYPES = [
    'basic',
    'manager'
  ]

  isManager(){
    return this.roleType === 'manager'
  }
}

export default Base.createResource(Role)