import React, {useState} from 'react'
import {Button, ButtonToolbar, Col, Form, Row, Table} from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import ResourceList from "../components/ResourceList"
import Organization from "../resources/Organization"
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceListSortTh from '../components/ResourceListSortTh'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronRight, faCubes, faCube} from "@fortawesome/free-solid-svg-icons"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import ResourceEditButton from "../components/ResourceEditButton"
import OrganizationModal from "../components/OrganizationModal"
import Breadcrumbs from '../lib/Breadcrumbs'
import ResourceCreateButton from "../components/ResourceCreateButton"
import ResourceFilter from "../components/ResourceFilter"
import FormInput from "../lib/FormInput"
import ExpandableList from "../lib/ExpandableList"

export default () => {
  const [showModal, setShowModal] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)

  const editResource = (resource, event) => {
    setSelectedResource(resource)
    setShowModal(true)
    event.stopPropagation()
  }

  const newResource = () => {
    setSelectedResource(Organization.build())
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedResource(null)
    setShowModal(false)
  }

  const showResource = (resource) => {
    setSelectedResource(resource)
    setShowDetails(true)
  }

  const filter = (filter, handleFilterChange) => (
    <Form>
      <Row className="justify-content-md-left">
        <Col md="auto" style={{width: 500}}>
          <FormInput name="search"
                     label="Name"
                     placeholder="- all -"
                     horizontal={10}
                     value={filter['search']}
                     onChange={handleFilterChange}/>
        </Col>
      </Row>
    </Form>
  )

  return (
    <ResourceContext baseQuery={Organization.includes('parent').where({})} defaultSort={{full_name: 'asc'}}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="organizations">
          New organization
        </ResourceCreateButton>
      </ButtonToolbar>
      <Breadcrumbs>
        Organizations
      </Breadcrumbs>
      <ResourceFilter>
        {filter}
      </ResourceFilter>
      <Table striped bordered hover>
        <thead>
        <tr>
          <ResourceListSortTh name="full_name">Name</ResourceListSortTh>
          <th>Remark</th>
          <th className="actions"></th>
        </tr>
        </thead>
        <tbody>
          <ResourceList>
            {resource => <tr className="clickable" key={resource.id} onClick={() => showResource(resource)}>
              <td>
                {resource.fullName}
                {resource.allowChildren &&
                <FontAwesomeIcon title="Parent company" className="float-right" icon={faCubes}/>}
                {resource.parent() &&
                <FontAwesomeIcon title="Child company" className="float-right" icon={faCube}/>}
              </td>
              <td>
                <ExpandableList>
                  {resource.canUpdate() && resource.remark?.split("\n")?.map((line, index) => <div key={`remark-line-${index}`}>{line}</div>)}
                </ExpandableList>
              </td>
              <td className="actions">
                <ResourceEditButton resource={resource} onClick={(event) => editResource(resource, event)}/>
                <ResourceDeleteButton resource={resource}/>
                <Button variant="link">
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </td>
            </tr>}
          </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <OrganizationModal resource={selectedResource} show={showModal} onClose={closeModal}/>
      {showDetails && selectedResource &&
      <Redirect to={`/organizations/${selectedResource.id}/instruments`}/>}
    </ResourceContext>
  )
}