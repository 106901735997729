import React, {useContext, useState} from 'react'
import {Form, Button, ButtonToolbar} from 'react-bootstrap'
import { RootContext } from "../contexts/RootContext"
import styles from '../styles/LoginForm.module.css'
import config from '../config'
import {useHistory} from "react-router-dom"
import Url from '../lib/Url'

export default () => {
  const [formState, setFormState] = useState({password: '', passwordConfirmation: ''})
  const { showMessage } = useContext(RootContext)
  const history = useHistory()

  const handleInputChange = (ev) => {
    const name = ev.target.name
    const value = ev.target.value
    setFormState({...formState, [name]: value})
  }

  const setPassword = () => {
    const fetchConfig = {
      method: 'PUT',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify({
        user:
          {
            password: formState.password,
            password_confirmation: formState.passwordConfirmation,
            reset_password_token: Url.getParam('reset_password_token')
          },
      })
    }

    const url = `${config.apiUrl}/auth/password`

    //console.log(fetchConfig)

    fetch(url, fetchConfig).then(response => response.json().then(obj => ({obj, response}))).then(
      ({obj, response}) => {
        if (!response.ok) {
          console.log("Response not Ok")
          return Promise.reject(response)
        } else {
          showMessage('New password set')
          history.replace('/login')
        }
      }).catch(err => {
      console.error(err)
      if (err.errors)
        showMessage(JSON.stringify(err.errors), 'danger')
      else
        showMessage('Network error', 'danger')
    })
  }

  return (
    <Form className={styles.form}>
      <h4>Set new password</h4>
      <Form.Group controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control autoFocus={true} type="password" name="password" value={formState.password} onChange={handleInputChange}/>
      </Form.Group>
      <Form.Group controlId="passwordConfirmation">
        <Form.Label>Password confirmation</Form.Label>
        <Form.Control type="password" name="passwordConfirmation" value={formState.passwordConfirmation} onChange={handleInputChange}/>
      </Form.Group>
      <ButtonToolbar className="float-right">
        <Button onClick={setPassword}>Change password</Button>
      </ButtonToolbar>
    </Form>
  )
}