import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, useLoadScript, Marker, OverlayView, MarkerClusterer, Polyline } from '@react-google-maps/api'
import config from '../config'

const WorldMap = (props) => {
  const minimumClusterSize = 2
  const fitBoundsTimeoutRef = useRef(null)
  const [ map, setMap ] = useState(null)
  const [ clusterTitles, setClusterTitles ] = useState([])
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey
    // ...otherOptions
  })

  // Handle focusing
  useEffect(() => {
    if (!props.fitBounds || !map)
      return
    if (fitBoundsTimeoutRef.current)
      clearTimeout(fitBoundsTimeoutRef.current)
    fitBoundsTimeoutRef.current = setTimeout(() => fitBounds(), 500)
    return () => {
        if (fitBoundsTimeoutRef.current)
          clearTimeout(fitBoundsTimeoutRef.current)
      }
    },
    [props.fitBounds, props.markers]
  )

  const fitBounds = () => {
    let bounds = new window.google.maps.LatLngBounds()
    props.markers.forEach((marker)=>{
      bounds.extend(marker)
    })

    // Extend for single marker
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      let extendPoint = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01)
      bounds.extend(extendPoint)
      extendPoint = new window.google.maps.LatLng(bounds.getSouthWest().lat() - 0.01, bounds.getSouthWest().lng() - 0.01)
      bounds.extend(extendPoint)
    }

    map.fitBounds(bounds)
  }

  const mapProps = (props) => {
    let ret = {};
    Object.keys(props).forEach(key => {
      if (props[key] !== undefined && key !== 'markers' && key !== 'fitBounds')
        ret[key] = props[key]
    })
    ret.onLoad = (map) => {setMap(map)}
    return ret
  }

  const markerIcon = (marker) => {
    if (!marker.icon)
      return null
    return {
      url: marker.icon,
      size: new window.google.maps.Size(45, 45),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(20, 45),
      scaledSize: new window.google.maps.Size(45, 45)
    }
  }

  const markerLabelOverlays = (marker, index) =>
    marker.labels.map((label, subIndex) =>
    <OverlayView key={`marker-label-${index}-${subIndex}`}
                 position={marker}
                 mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      {label}
    </OverlayView>)

  const MarkerLabels = () =>
    <React.Fragment>
      {props.markers.map((marker, index) => {
        if (!marker.labels || !marker.title || clusterTitles.indexOf(marker.title.split("\n")[0]) !== -1)
          return <span key={`marker-label-${index}`}/>
        return markerLabelOverlays(marker, index)
      })}
    </React.Fragment>

  const handleClusteringEnd = (clusterer) => {
    let titles = []
    clusterer.clusters.forEach((cluster, clusterIndex) => {
      if (cluster.getMarkers().length >= minimumClusterSize){
        titles.push(...cluster.getMarkers().map(m => m.title && m.title.split("\n")[0]))
      }
    })
    setClusterTitles(titles)
  }

  return (
    <React.Fragment>
      {isLoaded &&
      <GoogleMap {...mapProps(props)}>
        <MarkerClusterer
          onClusteringEnd={handleClusteringEnd}
          //ignoreHidden={true}
          //onClick={this.props.onMarkerClustererClick}
          averageCenter
          enableRetinaIcons
          minimumClusterSize={minimumClusterSize}
          gridSize={30}
        >
        {(clusterer) =>
        props.markers.map((marker, index) =>
          <Marker key={'marker-' + index}
                  animation={marker.animation ? window.google.maps.Animation[marker.animation] : null}
                  icon={markerIcon(marker)}
                  onClick={marker.onClick}
                  title={marker.title}
                  labelClass={marker.labelClass}
                  labelAnchor={new window.google.maps.Point(0, 0)}
                  clusterer={clusterer}
                  position={marker}/>)

        }
        </MarkerClusterer>
        <MarkerLabels/>
        {props.paths.map((path, pathIndex) =>
          path.map((point, index) =>
          <Polyline key={pathIndex + ',' + index} path={path} options={{strokeWeight: 1, strokeColor: '#FF0000'}}/>
          )
        )}
        {props.children}
      </GoogleMap>}
      {loadError && <span>Unable to load Google Maps</span>}
    </React.Fragment>
  )
}

WorldMap.propTypes = {
  zoom: PropTypes.number,
  onClick: PropTypes.func,
  markers: PropTypes.array,
  paths: PropTypes.array,
  center: PropTypes.object,
  fitBounds: PropTypes.bool,
  onDragStart: PropTypes.func,
  onZoomChanged: PropTypes.func,
  mapContainerClassName: PropTypes.string,
  mapContainerStyle: PropTypes.object
}

WorldMap.defaultProps = {
  mapContainerClassName: 'world-map-component',
  mapContainerStyle: undefined,
  markers: [],
  paths: [],
  zoom: 8,
  center: {lat: 62.79493487887006, lng: 27.158203125},
  fitBounds: false
}

export default WorldMap