import React, {useState, useEffect} from "react"
import FormTypeahead from "../lib/FormTypeahead"
import {Spinner} from "react-bootstrap"

export default ({instrument, errors, selectedSensors, setSelectedSensors, instrumentCalibrationSensors}) => {
  const [ sensors, setSensors ] = useState({})
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    // Add selected sensors to available options
    setSensors(instrumentCalibrationSensors.reduce((map, obj) => {
      map[obj.key] = obj.sensor()
      return map
    }, {}))
    preloadSensors().then(() => preselectSensors())
  }, [])

  const preselectSensors = () => {
    let sens = {}
    for (let sensor of instrument.requiredSensors.concat(instrument.optionalSensors)){
      const ics = instrumentCalibrationSensors.find(s => s.key === sensor.key)
      if (ics)
        sens[sensor.key] = ics.sensor()
      else
        sens[sensor.key] = null
    }
    setSelectedSensors(sens)
  }

  const preloadSensors = async () => {
    setLoading(true)
    let loadedSensors = {}
    for (let sensor of instrument.requiredSensors.concat(instrument.optionalSensors)){
      loadedSensors[sensor.key] = await loadSensors('', sensor.parameter, sensor.key)
    }
    setSensors({...sensors, ...loadedSensors})
    setLoading(false)
  }

  const loadSensors = (search, parameter) => {
    return instrument.availableSensors().where({parameter, search}).perPage(100).all().then(res => res.toArray())
  }

  // Suitable for typeahead
  const sensorOptionsByParameter = (key) => {
    return !sensors[key] ? [] : sensors[key].map(item => ({id: item.id, label: item.labelWithModel()}))
  }

  const handleTypeaheadInput = (value, parameter, key) => {
    loadSensors(value, parameter, key).then(result =>
      setSensors({...sensors, [key]: result})
    )
  }

  const findSensorById = (id) => {
    const allSensors = Object.keys(sensors).map(key => sensors[key]).flat()
    return allSensors.find(s => s.id === id)
  }

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (value && value.id)
      setSelectedSensors({...selectedSensors, [name]: findSensorById(value.id)})
    else
      setSelectedSensors({...selectedSensors, [name]: null})
  }

  const valueForTypeahead = (key) => {
    return !selectedSensors[key] ? null : {id: selectedSensors[key].id, label: selectedSensors[key].labelWithModel()}
  }

  const placeholder = (key) => {
    if (instrument.requiredSensors.map(s => s.key).includes(key))
      return '- select -'
    else if (instrument.optionalSensors.find(s => s.key === key).optionFor)
      return `- option for '${instrument.optionalSensors.find(s => s.key === key).optionFor}' -`
    else
      return '- optional -'
  }

  return (
    <React.Fragment>
      {loading &&
      <Spinner animation="border" role="status" size="sm">
        <span className="sr-only">Loading...</span>
      </Spinner>}
      {!loading && instrument.requiredSensors.concat(instrument.optionalSensors).map(sensor =>
      <FormTypeahead key={sensor.key}
                     name={sensor.key}
                     horizontal={true}
                     placeholder={placeholder(sensor.key)}
                     label={sensor.key}
                     options={sensorOptionsByParameter(sensor.key)}
                     value={valueForTypeahead(sensor.key)}
                     onInputChange={(value) => handleTypeaheadInput(value, sensor.parameter, sensor.key)}
                     error={errors[`${sensor.key}Sensor`]}
                     onChange={handleInputChange}/>)}
    </React.Fragment>
  )
}