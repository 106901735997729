import React, {useContext, useEffect, useState} from 'react'
import WorldMap from "../lib/WorldMap"
import styles from "../styles/InstrumentMap.module.css"
import InstrumentDataModal from "./InstrumentDataModal"
import {ResourceContext} from "../contexts/ResourceContext"
import useWindowDimensions from "../lib/useWindowDimensions"
import {faExclamationTriangle, faBatteryQuarter} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {InstrumentStatusContext} from "../contexts/InstrumentStatusContext"

export default () => {
  const [ showModal, setShowModal ] = useState(false)
  const [ selectedInstrument, setSelectedInstrument ] = useState(null)
  const [ fitBounds, setFitBounds ] = useState(true)
  const { windowHeight } = useWindowDimensions()
  const { resources, loading } = useContext(ResourceContext)
  const { lastUpdateTime, addInstruments, removeAllInstruments } = useContext(InstrumentStatusContext)

  useEffect(
    () => {
      if (resources){
        setFitBounds(true)
        addInstruments(resources.toArray())
      }
    },
    [resources]
  )

  useEffect(
    () => {
      if (loading) {
        removeAllInstruments()
      }
    },
    [loading]
  )

  const showInstrument = (instrument) => {
    setSelectedInstrument(instrument)
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedInstrument(null)
    setShowModal(false)
  }

  const labelMargin = (index, length) => {
    const angles = {
      0: Math.PI * 1.5 - 0 * Math.PI * 0.333,
      1: Math.PI * 1.5 - 1 * Math.PI * 0.333,
      2: Math.PI * 1.5 - 1.5 * Math.PI * 0.333,
      3: Math.PI * 1.5 - 2.0 * Math.PI * 0.333,
      4: Math.PI * 1.5 - 3 * Math.PI * 0.333,
    }
    return {
      marginTop: `${Math.sin(angles[index % 5]) * -38 - 33}px`,
      marginLeft: `${Math.cos(angles[index % 5]) * -20 + 3 - length * 0.5}px`,
      display: index > 4 ? 'none' : 'block'
    }
  }

  const MarkerLabel = ({instrument, index, children, length = 0}) => (
    <div className={styles.markerLabel} style={labelMargin(index, length)}>
      {children}
      {index === 0 && instrument.batteryLevel() !== null && instrument.batteryLevel() < 25 &&
      <FontAwesomeIcon className={styles.markerLabelBatteryWarning} icon={faBatteryQuarter}/>}
      {index === 0 && instrument.alarmIsTriggered() &&
      <FontAwesomeIcon className={styles.markerLabelWarning} icon={faExclamationTriangle} />}
    </div>
  )

  const resolveMarker = (instrument) => {
    const latestData = instrument.instrumentLatestData()
    let marker = {
      onClick: () => showInstrument(instrument),
      lat: parseFloat(latestData.lat),
      lng: parseFloat(latestData.lon),
      animation: instrument.alarmIsTriggered() ? 'BOUNCE' : 'DROP',
      icon: instrument.icon(),
      labels: instrument.valueLabels().map((label, index) =>
        <MarkerLabel key={index} index={index} instrument={instrument} length={index === 0 ? label.value.length * 6 : 0}>
          <span style={{display: 'none'}}>{lastUpdateTime(instrument.id)}</span>
          <span className={styles.labelValue}>{label.value}</span>
          <span className={styles.labelUnit}>{label.unit}</span>
        </MarkerLabel>)
    }
    marker.title = instrument.titleInfo()
    return marker
  }

  const markers = () => {
    if (!resources)
      return []
    let markersArray = []
    //console.log(lastUpdate)
    resources.toArray().forEach(instrument => {
      if (instrument.instrumentLatestData &&
        instrument.instrumentLatestData() &&
        instrument.instrumentLatestData().lon !== undefined &&
        instrument.instrumentLatestData().lon !== null){
        markersArray.push(resolveMarker(instrument))
      }
    })
    return markersArray
  }

  return (
    <React.Fragment>
      {resources && resources.empty() &&
      <div className={styles.emptyResultNotification}>
        No instruments found
      </div>}
      {resources && <React.Fragment>
        <WorldMap mapContainerStyle={{height: Math.max(windowHeight - 250, 400)}}
                  onDragStart={() => setFitBounds(false)}
                  markers={markers()}
                  fitBounds={fitBounds}/>
        <InstrumentDataModal instrument={selectedInstrument} show={showModal} onClose={closeModal}/>
      </React.Fragment>}
    </React.Fragment>
  )
}