import React, {useEffect, useState} from "react"
import {Button, Form, ListGroup} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import FormSelect from "../lib/FormSelect"
import UserGroup from "../resources/UserGroup"

export default ({selectedUserGroups, setSelectedUserGroups, resource, organization}) => {
  const [ userGroups, setUserGroups ] = useState([])

  useEffect(
    () => {
      let scope = UserGroup
      if (organization) {
        scope = organization.userGroups()
      }
      scope.perPage(1000).order({'organization.name': 'asc', 'name': 'asc'}).all().then(groups => {
        setUserGroups(groups.toArray())
      })
      if (resource.userGroups && resource.userGroups())
        setSelectedUserGroups(resource.userGroups().toArray())
    },
    [resource, organization]
  )

  const addUserGroup = (ev) => {
    const value = ev.target.value
    const userGroup = userGroups.find(group => parseInt(group.id, 10) === parseInt(value, 10))
    setSelectedUserGroups(selectedUserGroups.concat(userGroup))
  }

  const removeUserGroup = (group) => {
    setSelectedUserGroups(selectedUserGroups.filter(ug => ug !== group))
  }

  const userGroupOptions = () => {
    const excludeIds = selectedUserGroups.map(r => r.id)
    return userGroups.filter(g => !excludeIds.includes(g.id)).map(g => [g.id, g.uniqueName])
  }

  const userGroupsArePresent = () => {
    return userGroupOptions().length > 0 || selectedUserGroups.length > 0
  }

  return (
    <Form>
      {userGroupsArePresent() && <React.Fragment>
      <ListGroup variant="flush">
        {selectedUserGroups.map((group, index) => (
          <ListGroup.Item key={`group-${index}`}>
            {group.uniqueName}
            <Button className="float-right" variant="link-danger" size="sm" onClick={() => removeUserGroup(group)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
      {userGroupOptions().length > 0 &&
      <FormSelect
        name="userGroupId"
        label=""
        options={userGroupOptions()}
        blank="+ add privileged user group"
        onChange={addUserGroup}/>}
      </React.Fragment>}
      {!userGroupsArePresent() && <p>- no user groups defined -</p>}
    </Form>
  )
}