import React, {useContext, useEffect, useState} from 'react'
import {ResourceContext} from "../contexts/ResourceContext"
import {Container, Row, Col} from "react-bootstrap"
import InstrumentWidget from "./InstrumentWidget"
import InstrumentDataModal from "./InstrumentDataModal"
import styles from "../styles/InstrumentDashboard.module.css"
import useWindowDimensions from "../lib/useWindowDimensions"
import {InstrumentStatusContext} from "../contexts/InstrumentStatusContext"

export default () => {
  const { resources, loading } = useContext(ResourceContext)
  const [ showModal, setShowModal ] = useState(false)
  const [ selectedInstrument, setSelectedInstrument ] = useState(null)
  const { windowWidth } = useWindowDimensions()
  const { removeAllInstruments, addInstruments } = useContext(InstrumentStatusContext)

  useEffect(
    () => {
      if (resources)
        addInstruments(resources.toArray())
    },
    [resources]
  )

  useEffect(
    () => {
      if (loading)
        removeAllInstruments()
    },
    [loading]
  )

  const showInstrument = (instrument) => {
    setSelectedInstrument(instrument)
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedInstrument(null)
    setShowModal(false)
  }

  const columns = () => {
    if (windowWidth < 800)
      return 1
    else
      return Math.ceil(windowWidth / 620)
  }

  // Divide screen to suitable amount of columns and try to even widgets (height wise) in them
  const colResources = () => {
    if (!resources)
      return []
    const res = resources.toArray()
    const total = res.reduce((total, r) => total + ((r.descendantCount * 0.8) + 1), 0)
    const perCol = Math.floor(total / columns())
    let colIndex = 0
    let cols = []
    let cnt = 0
    res.forEach(r => {
      if (!cols[colIndex])
        cols[colIndex] = []
      cols[colIndex].push(r)
      cnt += (r.descendantCount * 0.8) + 1
      if (cnt >= perCol){
        colIndex ++
        colIndex %= columns()
        cnt = 0
      }
    })
    return cols
  }

  return (
    <React.Fragment>
      <div className={styles.divider}/>
      {resources && resources.empty() &&
      <div>
        No instruments found
      </div>}
      <Container fluid>
        <Row>
          {[...Array(columns()).keys()].map(index =>
          <Col key={index}>
            {colResources()[index] && colResources()[index].map(instrument => (
              <InstrumentWidget key={instrument.id} instrument={instrument} selectInstrument={showInstrument}/>
            ))}
          </Col>
          )}
        </Row>
      </Container>
      <InstrumentDataModal instrument={selectedInstrument} show={showModal} onClose={closeModal}/>
    </React.Fragment>
  )
}