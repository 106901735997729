import React, { useState } from 'react'
import {Table, ButtonToolbar, Form, Row, Col} from 'react-bootstrap'
import {Organization, User} from "../Resources"
import ResourceList from "../components/ResourceList"
import ResourceListSortTh from "../components/ResourceListSortTh"
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import ResourceEditButton from "../components/ResourceEditButton"
import UserModal from "../components/UserModal"
import {faAt, faMobile, faSms, faUserTie} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../lib/Breadcrumbs";
import ResourceCreateButton from "../components/ResourceCreateButton";
import ResourceFilter from "../components/ResourceFilter"
import FormInput from "../lib/FormInput"
import ExpandableList from "../lib/ExpandableList"
import useWindowDimensions from "../lib/useWindowDimensions"
import ResourceTypeahead from "../components/ResourceTypeahead"

export default () => {
  const [showModal, setShowModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)
  const { windowWidth } = useWindowDimensions()

  const editResource = (resource) => {
    setSelectedResource(resource)
    setShowModal(true)
  }

  const newResource = () => {
    setSelectedResource(User.build({ notifyEmail: true }))
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedResource(null)
    setShowModal(false)
  }

  const filter = (filter, handleFilterChange) => (
    <Form>
      <Row className="justify-content-md-left">
        <Col md="auto" style={{width: 500}}>
          <FormInput name="search"
                     label="Name"
                     placeholder="- all -"
                     horizontal={10}
                     value={filter['search']}
                     onChange={handleFilterChange}/>
        </Col>
        <Col md="auto" style={{width: 500}}>
          <ResourceTypeahead
            name="_organization.name"
            label="Organization"
            placeholder="- select -"
            horizontal={9}
            baseQuery={Organization.order({ name: 'asc' })}
            value={
              filter['organization.id']
                ? {
                  id: filter['organization.id'],
                  name: filter['_organization.name'] ?? ''
                }
                : null
            }
            clearButton
            labelResolver={(res) => res.name}
            onChange={(res) => {
              handleFilterChange(
                { target: { name: '_organization.name', value: res?.name ?? undefined} },
                {'organization.id': res?.id ?? undefined}
              )
            }}
          />
        </Col>
      </Row>
    </Form>
  )


  const UserGroupLabel = (role) => (
    <React.Fragment key={role.id}>
      <div
        style={{display: 'inline-block',
          maxWidth: Math.max(100, windowWidth / 4),
          fontSize: '0.9em',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {role.userGroup().uniqueName}
        {role.isManager() && <FontAwesomeIcon icon={faUserTie} title="Manager" style={{marginLeft: 8}}/>}
      </div>
      <br/>
    </React.Fragment>
  )

  return (
    <ResourceContext baseQuery={User.includes('roles.userGroup')} defaultSort={{admin: 'desc', name: 'asc'}}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="users">
          New user
        </ResourceCreateButton>
      </ButtonToolbar>
      <Breadcrumbs>
        Users
      </Breadcrumbs>
      <ResourceFilter>
        {filter}
      </ResourceFilter>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <ResourceListSortTh name="admin"/>
            <ResourceListSortTh name="name"/>
            <ResourceListSortTh name="email"/>
            <th>Groups</th>
            <th>Notify</th>
            <th className="actions"></th>
          </tr>
        </thead>
        <tbody>
          <ResourceList>
            {resource => <tr key={resource.id}>
              <td>{resource.admin ? 'Yes' : 'No'}</td>
              <td>{resource.name}</td>
              <td>{resource.email}</td>
              <td>
                <ExpandableList>
                {resource.roles().toArray().map(role => UserGroupLabel(role))}
                </ExpandableList>
              </td>
              <td>
                {resource.notifyEmail && <FontAwesomeIcon icon={faAt} title="By email" style={{marginRight: 5}}/>}
                {resource.notifySms && <FontAwesomeIcon icon={faSms} title="By SMS" style={{marginRight: 5}}/>}
                {resource.notifyPush && <FontAwesomeIcon icon={faMobile} title="By push notifications"/>}
              </td>
              <td className="actions">
                <ResourceEditButton resource={resource} onClick={() => editResource(resource)}/>
                <ResourceDeleteButton resource={resource}/>
              </td>
            </tr>}
          </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <UserModal resource={selectedResource} show={showModal} onClose={closeModal}/>
    </ResourceContext>
  )
}