import React from 'react'
import {Col, Form, Row} from "react-bootstrap"
import uncamelize from "./uncamelize"

export default ({name, label, checked, onChange, horizontal, disabled}) => {
  return (
    <Form.Group  as={horizontal ? Row : undefined} controlId={name}>
      {!horizontal &&
      <Form.Check name={name} type="checkbox"
                  label={label || uncamelize(name)}
                  checked={checked}
                  onChange={onChange}
                  disabled={disabled}/>}
      {horizontal &&
      <React.Fragment>
        <Form.Label column sm={4}>{label || uncamelize(name)}</Form.Label>
        <Col sm={8}>
          <Form.Check name={name} type="checkbox" checked={checked} onChange={onChange}/>
        </Col>
      </React.Fragment>}
    </Form.Group>
  )
}