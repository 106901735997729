import Base from './Base'

class Sensor extends Base {
  static className = 'Sensor'
  static define() {
    this.belongsTo('instrument')
    this.hasMany('data')
    this.hasMany('instruments')
    this.attributes('name', 'model', 'parameter', 'unit', 'latestDataValue', 'latestDataReceivedAt')
  }

  labelWithModel(){
    return `${this.name} (${this.parameter}, ${this.model})`
  }

  uniqueName(){
    return `${this.name} (${this.parameter}, ${this.unit})`
  }
}

export default Base.createResource(Sensor)