import React, {useEffect, useState} from 'react'
import {Link, Switch, useParams, useRouteMatch} from "react-router-dom"
import {Device} from "../Resources"
import {Button, ButtonGroup, Spinner} from "react-bootstrap"
import Breadcrumbs from "../lib/Breadcrumbs"
import SensorIndex from "./SensorIndex"
import DeviceModal from "../components/DeviceModal"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faSatelliteDish} from "@fortawesome/free-solid-svg-icons"
import {LinkContainer} from "react-router-bootstrap"
import AuthenticatedRoute from "../components/AuthenticatedRoute"

export default () => {
  const { deviceId } = useParams()
  const { url, path } = useRouteMatch()
  const [ loading, setLoading ] = useState(false)
  const [ device, setDevice ] = useState(false)
  const [ showModal, setShowModal ] = useState(false)

  useEffect(
    () => {
      if (deviceId){
        setLoading(true)
        Device.find(deviceId).then(resource => {
          setDevice(resource)
          setLoading(false)
        }).catch(err => {
          console.error(err)
          setLoading(false)
        })
      }
    },
    [deviceId]
  )

  return (
    <div>
      {loading &&
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>}
      {device && <React.Fragment>
        <Breadcrumbs>
          <Link to="/devices">
            Devices
          </Link>
          {`${device.id} (${device.model})`}
        </Breadcrumbs>
        <ButtonGroup style={{marginBottom: 10}}>
          <LinkContainer to={`${url}/sensors`}>
            <Button variant="dark">Sensors</Button>
          </LinkContainer>
          <Button variant="dark" title="Send downlink command" onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faSatelliteDish} />&nbsp;
            Downlink
          </Button>
        </ButtonGroup>
        <Switch>
          <AuthenticatedRoute path={`${path}/sensors`}>
            <SensorIndex device={device}/>
          </AuthenticatedRoute>
        </Switch>
      </React.Fragment>}
      <DeviceModal resource={device} show={showModal} onClose={() => setShowModal(false)}/>
    </div>
  )
}