import queryString from 'query-string'

class Url{
  static setQueryParams(queryParams){
    let params = Object.assign({}, queryParams)
    for(let key of Object.keys(params)){
      if (Array.isArray(params[key]) || typeof params[key] === 'object')
        params[key] = JSON.stringify(params[key])
    }
    this._updateUrl(params)
  }

  static setQueryParam(key, value){
    const params = queryString.parse(window.location.search)
    if (Array.isArray(value) || typeof value === 'object')
      params[key] = JSON.stringify(value)
    else
      params[key] = value
    this._updateUrl(params)
  }

  static getParam(key){
    const params = queryString.parse(window.location.search)
    return params[key]
  }

  static _updateUrl(params){
    // Update the url without triggering page refresh
    let search = '?' + queryString.stringify(params)
    const hashParts = window.location.hash.split('#')
    if (hashParts.length > 1)
      search += '#' + hashParts.slice(-1)[0]
    window.history.replaceState(null, null, search)
  }
}

export default Url