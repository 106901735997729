import Base from './Base'

class InstrumentAlarm extends Base {
  static className = 'InstrumentAlarm'
  static define() {
    this.hasMany('instrumentAlarmEvents')
    this.hasMany('instrumentAlarmCommands')
    this.hasMany('receivers', {className: 'User'})
    this.hasOne('instrument')
    this.attributes(
      'dataValueRanges',
      'offRangeTriggers',
      'dataAgeThresholdMins',
      'offRangeThresholdMins',
      'minEventIntervalMins',
      'stallingThresholdMins',
      'disabled',
      'disabledAt',
      'geofenceRadiusM',
      'geofenceLatitude',
      'geofenceLongitude'
    )
  }

  isEnabled(){
    return this.persisted() && !this.disabledAt
  }
}

export default Base.createResource(InstrumentAlarm)