import Base from './Base'

class Datum extends Base {
  static className = 'Datum'
  static define() {
    this.belongsTo('sensor')
    this.attributes('value', 'receivedAt')
  }
}

export default Base.createResource(Datum)