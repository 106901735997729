import React, {useContext, useEffect, useState} from 'react'
import { Form } from 'react-bootstrap'
import { ResourceContext } from '../contexts/ResourceContext'
import FormInput from '../lib/FormInput'
import ResourceModal from './ResourceModal'
import UserGroupsSelector from './UserGroupsSelector'
import { RootContext } from '../contexts/RootContext'
import CommandModalDownlinks from './CommandModalDownlinks'
import styles from '../styles/CommandModal.module.css'

export default ({resource, show, onClose}) => {
  const { auth } = useContext(RootContext)
  const [ formState, setFormState ] = useState({})
  const { reloadResources } = useContext(ResourceContext)
  const [ errors, setErrors ] = useState({})
  const [ selectedUserGroups, setSelectedUserGroups ] = useState([])

  useEffect(
    () => {
      if (resource){
        setFormState({
          organization: resource.organization(),
          name: resource.name,
          description: resource.description,
          downlinks: resource.downlinks
        })
      }
      setErrors({})
    },
    [resource]
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox')
      value = ev.target.checked
    let state = {...formState, [name]: value}
    setFormState(state)
  }

  const save = () => {
    const state = Object.assign({}, formState)
    resource.assignAttributes(state)
    resource.assignAttributes({userGroups: selectedUserGroups})
    const msg = resource.persisted() ? 'Command updated' : 'Command created'
    return resource.save().then(() => {
      reloadResources(msg)
      onClose()
    }).catch(err => {
      let errors = {}
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  return (
    <ResourceModal
        show={show}
        onClose={onClose}
        onSubmit={save}
        resource={resource}
        label="command"
        className={styles.modalDialog}
    >
      <Form>
        <FormInput
          readOnly
          onChange={(ev) => {}}
          horizontal
          name="organization"
          value={formState.organization && formState.organization.name}/>
        <FormInput
          horizontal
          name="name"
          value={formState.name}
          error={errors.name}
          onChange={handleInputChange}/>
        <FormInput
          horizontal
          rows={3}
          name="description"
          value={formState.description}
          error={errors.description}
          onChange={handleInputChange}/>
      </Form>
      {resource && <React.Fragment>
      <h5>
        Downlinks
        {formState.downlinks && formState.downlinks.length > 0 &&
        <span className={styles.totalCount}>(total: {formState.downlinks.length})</span>}
      </h5>
      <CommandModalDownlinks formState={formState} setFormState={setFormState}/>
      {formState.organization && <div style={{marginTop: 16}}>
        <h5>User groups</h5>
        <UserGroupsSelector resource={resource}
                            organization={formState.organization}
          // For new record and non-admin user, pre-select the first user group available
                                   preselect={!resource.persisted() && !auth.user.admin}
                                   selectedUserGroups={selectedUserGroups}
                                   setSelectedUserGroups={setSelectedUserGroups}/>
      </div>}
      </React.Fragment>}
    </ResourceModal>
  )
}
