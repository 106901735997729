import { createResourceLibrary } from 'active-resource/build/active-resource'
import config from "../config"

const jwt = () => {
  if (window.localStorage.getItem('auth'))
    return JSON.parse(window.localStorage.getItem('auth')).jwt
  else
    return ''
}

export default createResourceLibrary(
  config.jsonApiUrl, // base url for your server
  {
    headers: { 'Content-Type': 'application/vnd.api+json', Authorization: `Bearer ${jwt()}` }
  }
)