import FormTypeahead from "../lib/FormTypeahead"
import React, {useEffect, useRef, useState} from "react"

const ResourceTypeahead = ({
  name,
  label,
  placeholder,
  value,
  excludeIds,
  onChange,
  baseQuery,
  clearButton,
  horizontal,
  labelResolver
}) => {
  const [resources, setResources] = useState([])
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const searchTimeout = useRef(null)

  useEffect(
    () => {
      fetchResources(search, page)
    },
    []
  )

  const fetchResources = (search, page) => {
    // Typeahead default is to show 25 per page use +1
    baseQuery.where({ search }).perPage(26).page(page).all().then(res => {
      const newResources = [...resources]
      for (const u of res.toArray()){
        if (newResources.find(usr => usr.id === u.id) === undefined) {
          newResources.push(u)
        }
      }
      setResources(newResources)
    })
  }

  const handleSearch = (value) => {
    window.clearTimeout(searchTimeout.current)
    searchTimeout.current = window.setTimeout(
      () => {
        setPage(1)
        setSearch(value)
        fetchResources(value, 1)
      },
      200
    )
  }

  const handlePaginate = () => {
    const newPage = page + 1
    setPage(newPage)
    fetchResources(search, newPage)
  }

  const handleChange = (ev) => {
    const res = resources.find(res => res.id === ev.target.value.id)
    if (res) {
      onChange(res)
    } else {
      onChange(null)
    }
  }

  const options = resources
    .filter(g => !(excludeIds ?? []).includes(g.id))
    .map(res => ({ id: res.id, label: labelResolver(res) }))

  return (
    <FormTypeahead
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      value={value ? { id: value.id, label: labelResolver(value) } : null}
      onChange={handleChange}
      onInputChange={handleSearch}
      onPaginate={handlePaginate}
      clearButton={clearButton}
      horizontal={horizontal}
    />
  )
}

export default ResourceTypeahead
