import React, {useState} from 'react'
import styles from '../styles/InstrumentInfo.module.css'
import InstrumentModal from "../components/InstrumentModal"
import { Button, Row, Col } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import Instrument from "../resources/Instrument"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import Time from '../lib/Time'
import {faMapMarked} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {LinkContainer} from "react-router-bootstrap"
import {InstrumentClone} from "../Resources"
import InstrumentCloneModal from "../components/InstrumentCloneModal"
import InstrumentOfflineModal from "../components/InstrumentOfflineModal"
import InstrumentOnlineModal from "../components/InstrumentOnlineModal"
import InstrumentActionsButton from "../components/InstrumentActionsButton"

export default ({instrument}) => {
  const [showModal, setShowModal] = useState(null)
  const [instrumentClone, setInstrumentClone] = useState(null)
  const history = useHistory()

  const editResource = (event) => {
    setShowModal('instrument')
    event.stopPropagation()
  }

  const offlineResource = (event) => {
    setShowModal('instrumentOffline')
    event.stopPropagation()
  }

  const onlineResource = (event) => {
    setShowModal('instrumentOnline')
    event.stopPropagation()
  }

  const cloneResource = (event) => {
    const clone = InstrumentClone.build({
      instrument: instrument,
      organization: instrument.organization(),
      parent: instrument.parent(),
      sourceOfflineFrom: new Date().toJSON(),
      calibrationValidFrom: new Date().toJSON(),
      name: instrument.name + ' (clone)'
    })
    setInstrumentClone(clone)
    setShowModal('instrumentClone')
    event.stopPropagation()
  }

  const handleModalClose = (clonedInstrument) => {
    setInstrumentClone(null)
    setShowModal(null)
    if (clonedInstrument) {
      history.push(`/instruments/${clonedInstrument.id}/info`)
    }
  }

  const onDelete = () => {
    history.push(`/organizations/${instrument.organization().id}/instruments`)
  }

  const plotOnMapUrl = () => {
    let params = {
      'id': instrument.id,
      '_name': instrument.rootName(),
      'organization.id': instrument.organization().id,
      '_organization.name': instrument.organization().name
    }
    return `/map?filter=${encodeURIComponent(JSON.stringify(params))}`
  }

  return (
    <div className={styles.infoCard}>
      <Row>
        <Col>
          <h4>{instrument.icon() && <img alt="icon" className={styles.icon} src={instrument.icon()}/>} {instrument.name}</h4>
        </Col>
        <Col style={{textAlign: 'right'}}>
          <InstrumentActionsButton
            resource={instrument}
            variant="dark"
            onEdit={editResource}
            onTakeOffline={offlineResource}
            onTakeOnline={onlineResource}
            onClone={cloneResource}
          />
          &nbsp;
          <ResourceDeleteButton resource={instrument} variant="dark" onDelete={onDelete}/>
          &nbsp;
          <LinkContainer to={plotOnMapUrl()}>
            <Button variant="dark" title="Plot on map" disabled={instrument.disabledAt}>
              <FontAwesomeIcon icon={faMapMarked}/>
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      <dl className='row'>
        <dt className="col-md-2">Type</dt>
        <dd className="col-md-10">{instrument.typeLabel()}</dd>
        <dt className="col-md-2">Organization</dt>
        <dd className="col-md-10">{instrument.organization().name}</dd>
        <dt className="col-md-2">Parent group</dt>
        <dd className="col-md-10">{instrument.ancestors.length > 0 ? instrument.ancestors.map(a => a.name).join(' / ') : '-'}</dd>
        <dt className="col-md-2">Tags</dt>
        <dd className="col-md-10">{instrument.tags().size() > 0 ? instrument.tags().toArray().map(g => g.name).join(', ') : '-'}</dd>
        <dt className="col-md-2">User groups</dt>
        <dd className="col-md-10">{instrument.userGroups().toArray().map(g => g.name).join(', ')} {instrument.useParentUserGroups && <i>(uses parent privileges)</i>}</dd>
        {!instrument.group &&
        <React.Fragment>
          <dt className="col-md-2">Data resolution</dt>
          <dd className="col-md-10">{instrument.dataResolution ? Instrument.dataResolutions[instrument.dataResolution] : '-'}</dd>
          <dt className="col-md-2">Data graph</dt>
          <dd className="col-md-10">{instrument.uiSettings && Object.keys(instrument.uiSettings).length > 0 ? JSON.stringify(instrument.uiSettings) : '- automatic -'}</dd>
        </React.Fragment>}
        {instrument.group &&
        <React.Fragment>
          <dt className="col-md-2">Background</dt>
          <dd className="col-md-10">{instrument.backgroundImageFilename || '-'}</dd>
        </React.Fragment>}
        <dt className="col-md-2">Public</dt>
        <dd className="col-md-10">{instrument.public ? 'Yes' : 'No'}</dd>
        <dt className="col-md-2">Priority</dt>
        <dd className="col-md-10">{instrument.priority ? 'Yes' : 'No'}</dd>
        {instrument.disabledAt &&
        <React.Fragment>
          <dt className="col-md-2">Disabled</dt>
          <dd className="col-md-10"><Time>{instrument.disabledAt}</Time> - {instrument.updaterInfo}</dd>
        </React.Fragment>}
        <dt className="col-md-2">Short name</dt>
        <dd className="col-md-10">{instrument.shortName}</dd>
        <dt className="col-md-2">Description</dt>
        <dd className="col-md-10">{instrument.description && instrument.description.split("\n").map(line => <div>{line}</div>)}</dd>
        <dt className="col-md-2">Remark</dt>
        <dd className="col-md-10">{instrument.remark && instrument.remark.split("\n").map(line => <div>{line}</div>)}</dd>
        <dt className="col-md-2">Created</dt>
        <dd className="col-md-10"><Time>{instrument.createdAt}</Time> - {instrument.creatorInfo}</dd>
        <dt className="col-md-2">Updated</dt>
        <dd className="col-md-10">
          {instrument.updaterInfo && <span>
            <Time>{instrument.updatedAt}</Time> - {instrument.updaterInfo}
          </span>}
          {!instrument.updaterInfo && <span>
            -
          </span>}
        </dd>
      </dl>
      <InstrumentModal resource={instrument} show={showModal === 'instrument'} onClose={handleModalClose}/>
      <InstrumentCloneModal resource={instrumentClone} show={showModal === 'instrumentClone'} onClose={handleModalClose}/>
      <InstrumentOfflineModal resource={instrument} show={showModal === 'instrumentOffline'} onClose={handleModalClose}/>
      <InstrumentOnlineModal resource={instrument} show={showModal === 'instrumentOnline'} onClose={handleModalClose}/>
    </div>
  )
}