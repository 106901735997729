import React from 'react'
import Time from '../lib/Time'
import config from '../config'

class InstrumentDatum{

  constructor(instrument){
    this.instrument = instrument
  }

  fetchData(params = ''){
    const config = {
      method: 'GET',
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem('auth') || '{}').jwt}`
      }
    }

    // Custom data link provided by the api for instruments
    const url = this.instrument.__links.data + '?' + params

    return fetch(url, config).then(response => response.json().then(data => ({data, response}))).then(
      ({data, response}) => {
        if (!response.ok)
          return Promise.reject(response)
        return {
          data: this._mapData(data),
          totals: this._mapTotals(data.meta.totals)
        }
      }).catch(err => {
      console.log(err)
    })
  }

  _mapTotals(totals){
    return totals.map(d => ({
      key: d.key,
      value: d.value,
      label: InstrumentDatum.resolveLabel(d.key),
      unit: d.unit !== 'n' ? d.unit : ''
    }))
  }

  _mapData(data){
    return data.data.map(d => {
      const values = data.meta.data_attributes.map((da, index) => ({
        key: da.key,
        value: d.attributes.values[index],
        label: InstrumentDatum.resolveLabel(da.key),
        unit: da.unit !== 'n' ? da.unit : ''
      }))
      return Object.assign(d.attributes, {time: Time.parseUTC(d.id), values: values})
    })
  }

  async dataForChart(fromTime, toTime, mode){
    if (!toTime || (fromTime && toTime && toTime.valueOf() < fromTime.valueOf()))
      return {data: [], totals: []}
    if (mode === 'dayAvg') {
      return this.fetchData(`filter[from]=${fromTime.toISOString()}&filter[to]=${toTime.toISOString()}&page[size]=${config.maxDataBatchSize}&filter[resolution]=daily_avg&filter[tz]=${Time.timezone()}`)
    } else if (mode === 'hourAvg') {
      return this.fetchData(`filter[from]=${fromTime.toISOString()}&filter[to]=${toTime.toISOString()}&page[size]=${config.maxDataBatchSize}&filter[resolution]=hourly_avg`)
    } else {
      return this.fetchData(`filter[from]=${fromTime.toISOString()}&filter[to]=${toTime.toISOString()}&page[size]=${config.maxDataBatchSize}`)
    }
  }

  static chartOptions(attribute, instrumentType){
    let ret
    switch(attribute) {
      case 'pressure':
        if (instrumentType === 'pressure_sensor')
          ret = {yAxisDomain: [dataMin => Math.min(dataMin, -20), dataMax => Math.max(dataMax, 20)]}
        else if (instrumentType === 'pressure_abs_sensor')
          ret = {yAxisDomain: [dataMin => Math.min(dataMin, 0), dataMax => Math.max(dataMax, 2000)]}
        break
      case 'snow_depth':
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 150)]}
        break
      case 'motion':
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 25)]}
        break
      case 'status':
      case 'switch_state':
      case 'heating':
      case 'gate_state':
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 2)]}
        break
      case 'alarm_state':
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 5)]}
        break
      case 'power':
      case 'power_consumption':
      case 'co2':
      case 'tvoc':
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 1000)]}
        break
      case 'temp':
        ret = {yAxisDomain: [dataMin => Math.min(dataMin, -40), dataMax => Math.max(dataMax, 40)]}
        break
      case 'slow_a':
      case 'fast_a':
      case 'leq_a':
      case 'slow_c':
      case 'fast_c':
      case 'leq_c':
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 150)]}
        break
      default:
        ret = {yAxisDomain: [0, dataMax => Math.max(dataMax, 100)]}
    }
    return ret
  }

  static resolveLabel(key){
    if (key === 'slow_a')
      return 'Slow A'
    else if (key === 'slow_c')
      return 'Slow C'
    else if (key === 'fast_a')
      return 'Fast A'
    else if (key === 'fast_c')
      return 'Fast C'
    else if (key === 'leq_a')
      return <span>L<sub>eq</sub> A</span>
    else if (key === 'leq_c')
      return <span>L<sub>eq</sub> C</span>
    else if (key === 'co2')
      return <span>CO<sub>2</sub></span>
    else if (key === 'pm25')
      return <span>PM<sub>2.5</sub></span>
    else if (key === 'pm10')
      return <span>PM<sub>10</sub></span>
    else if (key === 'tvoc')
      return 'tVOC'
    else if (key === 'temp_min_in')
      return 'Temp min in'
    else if (key === 'gate_state' || key === 'switch_state' || key === 'alarm_state')
      return 'State'
    return key.charAt(0).toUpperCase() + key.replace('_', ' ').slice(1)
  }
}

export default InstrumentDatum