import Base from './Base'

class UserGroup extends Base {
  static className = 'UserGroup'
  static define() {
    this.belongsTo('organization')
    this.hasMany('roles')
    this.attributes('name')
  }
}

export default Base.createResource(UserGroup)