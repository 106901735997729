import React from "react"
import { useContext } from "react"
import { ThemeContext } from "../contexts/ThemeContext"

export default () => {
  const { Footer } = useContext(ThemeContext)

  return (
    <Footer/>
  )
}