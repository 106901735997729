import React, {useEffect, useState} from 'react'
import {Col, Dropdown, Form} from "react-bootstrap"
import styles from "../styles/InstrumentDataModal.module.css"
import DateTime from "react-datetime"
import moment from "moment-timezone"

export default ({fromTime, toTime, setFromTime, setToTime, setRequestedDataMode, requestedDataMode}) => {
  const [ timeMode, setTimeMode ] = useState('day')

  useEffect(() => {
    switch (timeMode){
      case 'day':
        setToTime(toTime.endOf('day'))
        setFromTime(moment(toTime).subtract(1, 'days').add(1, 'second'))
        break
      case 'twoDays':
        setToTime(toTime.endOf('day'))
        setFromTime(moment(toTime).subtract(2, 'days').add(1, 'second'))
        break
      case 'fiveDays':
        setToTime(toTime.endOf('day'))
        setFromTime(moment(toTime).subtract(5, 'days').add(1, 'second'))
        break
      case 'sevenDays':
        setToTime(toTime.endOf('day'))
        setFromTime(moment(toTime).subtract(7, 'days').add(1, 'second'))
        break
      case 'tenDays':
        setToTime(toTime.endOf('day'))
        setFromTime(moment(toTime).subtract(10, 'days').add(1, 'second'))
        break
      case 'fourteenDays':
        setToTime(toTime.endOf('day'))
        setFromTime(moment(toTime).subtract(14, 'days').add(1, 'second'))
        break
      case 'thirtyDays':
        setToTime(toTime.endOf('day'))
        setFromTime(moment(toTime).subtract(30, 'days').add(1, 'second'))
        break
      case 'custom':
        if (fromTime.valueOf() > toTime.valueOf())
          setFromTime(toTime.startOf('day'))
        break
      default:
        console.error("Unknown time span mode: " + timeMode)
    }
  }, [toTime, timeMode])

  const timeModes = {
    day: '24 hours',
    twoDays: '2 days',
    fiveDays: '5 days',
    sevenDays: '7 days',
    tenDays: '10 days',
    fourteenDays: '14 days',
    thirtyDays: '30 days',
    custom: 'Custom'
  }

  const handleFromTimeChange = (momentTime) => {
    let resolvedValue = ''
    if (typeof momentTime === "object"){
      resolvedValue = momentTime
      if (resolvedValue.valueOf() > toTime.valueOf())
        resolvedValue = toTime
    }
    setFromTime(resolvedValue)
  }

  const handleToTimeChange = (momentTime) => {
    let resolvedValue = ''
    if (typeof momentTime === "object"){
      resolvedValue = momentTime
    }
    setToTime(resolvedValue)
  }

  return (
    <React.Fragment>
      <Col xs="auto" className={styles.timeFilterItem}>
        <Dropdown>
          <Dropdown.Toggle size="sm" variant="dark" id="dropdown-basic" className={styles.timeSpanDropdownToggle}>
            {timeModes[timeMode]}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(timeModes).map(mode => (
              <Dropdown.Item key={mode}
                             disabled={false}
                             onClick={() => setTimeMode(mode)}>
                {timeModes[mode]}
              </Dropdown.Item>
            ))}
            <Dropdown.Divider />
            <Dropdown.ItemText className={styles.avgCheckBox}>
              <Form.Check label="Hourly avg"
                          name="OneHourAvg"
                          type="checkbox"
                          checked={requestedDataMode === 'hourAvg'}
                          onChange={() => {setRequestedDataMode(requestedDataMode === 'hourAvg' ? null : 'hourAvg')}}/>
            </Dropdown.ItemText>
            <Dropdown.ItemText className={styles.avgCheckBox}>
              <Form.Check label="Daily avg"
                          name="DailyAvg"
                          type="checkbox"
                          checked={requestedDataMode === 'dayAvg'}
                          onChange={() => {setRequestedDataMode(requestedDataMode === 'dayAvg' ? null : 'dayAvg')}}/>
            </Dropdown.ItemText>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs="auto" className={styles.timeFilterItem}>
        {timeMode === 'custom' && <React.Fragment>
          <DateTime name="fromTime"
                  inputProps={{readOnly: true}}
                  className={[styles.datePicker, styles.datePickerWithTime].join(' ')}
                  onChange={handleFromTimeChange}
                  value={fromTime || ''}
                  viewDate={fromTime}
                  closeOnSelect={false}
                  timeFormat="HH:mm"
                  dateFormat="MMM Do YYYY"/>
          <span>&nbsp;-&nbsp;</span>
        </React.Fragment>}
        <DateTime name="toTime"
                  inputProps={{readOnly: true}}
                  className={[styles.datePicker, timeMode === 'custom' && styles.datePickerWithTime].join(' ')}
                  onChange={handleToTimeChange}
                  value={toTime || ''}
                  viewDate={toTime}
                  closeOnSelect={false}
                  timeFormat={timeMode === 'custom' ? "HH:mm" : ""}
                  dateFormat="MMM Do YYYY"/>
      </Col>
    </React.Fragment>
  )
}