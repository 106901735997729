import React, {useContext, useEffect, useRef} from "react"
import useWindowDimensions from "../lib/useWindowDimensions"
import styles from "../styles/InstrumentBackground.module.css"
import {InstrumentStatusContext} from "../contexts/InstrumentStatusContext"

export default ({children, instrument, onOffsetChange}) => {
  const imageRef = useRef(null)
  const { windowHeight, windowWidth } = useWindowDimensions()
  const { lastUpdateTime } = useContext(InstrumentStatusContext)

  useEffect(() => {
    loadBackground(instrument)
  }, [instrument])

  const loadBackground = (instrument) => {
    const url = instrument.__links.background_image
    if (!url)
      return
    const config = {
      method: 'GET',
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem('auth') || '{}').jwt}`
      }
    }
    fetch(url, config).then(response => response.blob().then(data => ({data, response}))).then(
      ({data, response}) => {
        if (response.ok) {
          const url = URL.createObjectURL(data)
          imageRef.current.style.background = `url('${url}')`
          imageRef.current.style.backgroundSize = 'cover'
          imageRef.current.style.backgroundRepeat = 'no-repeat'
        }
      }).catch(err => {
        console.log(err)
      })
  }

  const dimensions = () => {
    let width = instrument.backgroundImageMeta.width || 1
    let height = instrument.backgroundImageMeta.height || 1
    const ratio = width / height
    height = windowHeight - 180
    width = height * ratio
    if (width > windowWidth * 0.9) {
      width = windowWidth * 0.9
      height = width / ratio
    }
    return {
      width,
      height
    }
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const id = parseInt(event.dataTransfer.getData('instrumentId'), 10)
    const originalX = parseInt(event.dataTransfer.getData('clientX'), 10)
    const originalY = parseInt(event.dataTransfer.getData('clientY'), 10)
    const offsetX = parseFloat(event.dataTransfer.getData('offsetX'))
    const offsetY = parseFloat(event.dataTransfer.getData('offsetY'))
    const deltaY = event.clientY - originalY
    const deltaX = event.clientX - originalX
    const newOffset = {
      y: Math.min(Math.max((deltaY / dimensions().height) + offsetY, 0), 1),
      x: Math.min(Math.max((deltaX / dimensions().width) + offsetX, 0), 1)
    }
    onOffsetChange(id, newOffset)
  }

  return (
    <div
      className={styles.container}
      ref={imageRef}
      style={dimensions()}
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    >
      <span style={{display: 'none'}}>{lastUpdateTime()}</span>
      {children}
    </div>
  )
}