import Base from './Base'

class InstrumentStatusReport extends Base {
  static className = 'InstrumentStatusReport'

  static intervals = {
    monthly: 'Monthly',
    weekly: 'Weekly',
    daily: 'Daily'
  }

  static reportTypes = {
    fire_alarm: 'Fire alarms report',
    temp_sensor: 'Temperature report'
  }

  static define() {
    this.belongsTo('organization')
    this.attributes('name', 'interval', 'reportType', 'receiverEmails', 'disabled', 'disabledAt')
  }
}

export default Base.createResource(InstrumentStatusReport)
