import React, { useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import ResourceList from "../components/ResourceList"
import { Device, Instrument } from "../Resources"
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceListSortTh from '../components/ResourceListSortTh'
import Breadcrumbs from '../lib/Breadcrumbs'
import Time from '../lib/Time'
import { Row, Col, Button } from 'react-bootstrap'
import { faChevronRight, faSatelliteDish } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ResourceFilter from "../components/ResourceFilter"
import ExpandableList from "../lib/ExpandableList"
import useWindowDimensions from "../lib/useWindowDimensions"
import { useHistory } from "react-router-dom"
import DeviceModal from "../components/DeviceModal"
import FormInput from "../lib/FormInput"
import ResourceTypeahead from "../components/ResourceTypeahead"

export default () => {
  const [ downlinkDevice, setDownlinkDevice ] = useState(null)
  const { windowWidth } = useWindowDimensions()
  const history = useHistory()

  const InstrumentLink = ({instrument}) => (
    <React.Fragment>
      <Button variant="link" size="sm" onClick={(ev) => {
        history.push(`/instruments/${instrument.id}`)
        ev.stopPropagation()
      }}>
        <div style={{display: 'inline-block',
          maxWidth: Math.max(100, windowWidth / 4),
          fontSize: '0.9em',
          overflow: 'hidden',
          textOverflow: 'ellipsis'}}>
          {instrument.fullName}
        </div>
      </Button>
      <br/>
    </React.Fragment>
  )

  const filter = (filter, handleFilterChange) => (
    <Form>
      <Row className="justify-content-md-left">
        <Col md="auto">
          <FormInput name="search"
                     label="ID"
                     placeholder="- all -"
                     horizontal={10}
                     value={filter.search}
                     onChange={handleFilterChange}/>
        </Col>
        <Col md="auto" style={{width: 600}}>
          <ResourceTypeahead
            name="_instrument.name"
            label="Instrument"
            placeholder="- select -"
            horizontal={9}
            clearButton
            value={
              filter['instrument.id']
              ? {
                  id: filter['instrument.id'],
                  fullName: filter['_instrument.name'] ?? ''
                }
              : null
            }
            baseQuery={Instrument.order({ id: 'desc' })}
            labelResolver={(res) => res.fullName}
            onChange={(res) => {
              handleFilterChange(
                { target: { name: '_instrument.name', value: res?.fullName ?? undefined} },
                {'instrument.id': res?.id ?? undefined}
              )
            }}
          />
        </Col>
      </Row>
    </Form>
  )

  const showResource = (resource) => {
    history.push(`devices/${resource.id}/sensors`)
  }

  const openDownlinkModal = (ev, resource) => {
    setDownlinkDevice(resource)
    ev.stopPropagation()
  }

  const batteryTitle = (resource ) => (
    [ resource.batteryLevel != null && `${resource.batteryLevel}%`,
      resource.batteryVoltage != null && `${resource.batteryVoltage}V` ].filter(i => i).join(', ')
  )

  const batteryLabel = (resource) => (
    resource.batteryLevel != null ? `${resource.batteryLevel}%` :
      (resource.batteryVoltage != null ? `${resource.batteryVoltage}V` : '')
  )

  const baseQuery = () => {
    return Device.includes('instruments').perPage(15)
  }

  return (
    <ResourceContext baseQuery={baseQuery()} defaultSort={{createdAt: 'desc'}}>
      <Breadcrumbs>
        Devices
      </Breadcrumbs>
      <ResourceFilter>
        {filter}
      </ResourceFilter>
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <ResourceListSortTh name="id">ID</ResourceListSortTh>
          <ResourceListSortTh name="model"/>
          <th>Instruments</th>
          <th>Batt</th>
          <th>SF</th>
          <th>Interval</th>
          <th>Data received</th>
          <ResourceListSortTh name="createdAt">Created at</ResourceListSortTh>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource =>
          <tr className="clickable" key={resource.id} onClick={() => resource.canShow() && showResource(resource)}>
            <td>{resource.id}</td>
            <td style={{whiteSpace: "nowrap"}}>{resource.model}</td>
            <td>
              <ExpandableList>
                {resource.instruments().toArray().map(i => i && <InstrumentLink key={i.id} instrument={i}/>)}
              </ExpandableList>
            </td>
            <td title={batteryTitle(resource)}>{batteryLabel(resource)}</td>
            <td>{resource.spreadingFactor}</td>
            <td>{resource.transmitIntervalSec ? Time.formatDuration(Math.round(resource.transmitIntervalSec / 60) * 60000)  : ''}</td>
            <td title={Time.formatTime(resource.latestDataReceivedAt)}>
              <Time.DurationToNow postfix="ago">{resource.latestDataReceivedAt}</Time.DurationToNow>
            </td>
            <td style={{whiteSpace: "nowrap"}} title={Time.formatTime(resource.createdAt)}>
              <Time timeFormat={null}>{resource.createdAt}</Time>
            </td>
            <td className="actions">
              <Button variant="link" title="Send downlink command" onClick={(ev) => openDownlinkModal(ev, resource)}>
                <FontAwesomeIcon icon={faSatelliteDish} />
              </Button>
              {resource.canShow() &&
              <Button variant="link">
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>}
            </td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <DeviceModal resource={downlinkDevice} show={downlinkDevice !== null} onClose={() => setDownlinkDevice(null)}/>
    </ResourceContext>
  )
}