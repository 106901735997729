import React from 'react'
import styles from '../styles/InstrumentBackground.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBatteryQuarter, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons"

export default ({instrument, onClick, offset, showName}) => {
  const markerStyle = () => {
    return {
      top: `${offset.y * 100}%`,
      left: `${offset.x * 100}%`
    }
  }

  const handleDragStart = (event) => {
    event.dataTransfer.setData('instrumentId', instrument.id)
    event.dataTransfer.setData('clientX', event.clientX)
    event.dataTransfer.setData('clientY', event.clientY)
    event.dataTransfer.setData('offsetX', offset.x)
    event.dataTransfer.setData('offsetY', offset.y)
  }

  const shouldShowName = () => {
    return (
        (instrument.batteryLevel() !== null && instrument.batteryLevel() < 25) ||
        instrument.alarmIsTriggered() ||
        (instrument.shortName && instrument.shortName !== '') ||
        showName
    )
  }

  const shouldShowShortName = () => {
    return instrument.shortName && instrument.shortName !== '' && !showName
  }

  return (
    <div className={styles.marker} style={markerStyle()}>
      <div className={styles.markerContent}>
        <img className={styles.markerImage}
             draggable={instrument.canUpdate()}
             onDragStart={handleDragStart}
             onClick={onClick}
             title={instrument.titleInfo()}
             src={instrument.icon()} />
        {shouldShowName() &&
        <div className={styles.markerInstrumentName}>
          {shouldShowShortName() ? <strong>{instrument.shortName}</strong> : (showName && instrument.name)}
          {instrument.batteryLevel() !== null && instrument.batteryLevel() < 25 &&
          <FontAwesomeIcon className={styles.markerLabelBatteryWarning} icon={faBatteryQuarter}/>}
          {instrument.alarmIsTriggered() &&
          <FontAwesomeIcon className={styles.markerLabelWarning} icon={faExclamationTriangle} />}
        </div>}
        <div className={styles.markerLabelContainer}>
          {instrument.valueLabels().map((label, index) => (
            index > 0 ? <div className={styles.markerLabel} key={'bg-marker-' + index}>
              <span className={styles.markerLabelValue}>
                {label.value}
              </span>
              <span className={styles.markerLabelUnit}>
                {label.unit}
              </span>
            </div> : null
          ))}
        </div>
      </div>
    </div>
  )
}
