import Base from './Base'

class EventLog extends Base {
  static className = 'EventLog'
  static define() {
    this.belongsTo('user')
  }
}

export default Base.createResource(EventLog)
