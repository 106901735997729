import Base from './Base'

class InstrumentCalibrationSensor extends Base {
  static className = 'InstrumentCalibrationSensor'
  static define() {
    this.belongsTo('instrumentCalibration')
    this.belongsTo('sensor')
    this.attributes('key')
  }
}

export default Base.createResource(InstrumentCalibrationSensor)