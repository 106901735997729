import React, { useContext, useState } from "react"
import {Button, ButtonToolbar, Overlay, Popover, Spinner} from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { ResourceContext } from "../contexts/ResourceContext"

export default ({resource, variant, label, size, onDelete}) => {
  const { reloadResources } = useContext(ResourceContext)
  const [ showOverlay, setShowOverlay ] = useState(false)
  const [ overlayTarget, setOverlayTarget ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const deleteResource = (event) => {
    setLoading(true)
    resource.destroy().then(() => {
      reloadResources("Resource deleted")
      setLoading(false)
      setShowOverlay(false)
      if (onDelete)
        onDelete()
    }).catch(err => {
      console.error(err)
      setLoading(false)
      setShowOverlay(false)
    })
    event.stopPropagation()
  }

  const hideOverlay = (event) => {
    setShowOverlay(false)
    event.stopPropagation()
  }

  const handleClick = (event) => {
    setOverlayTarget(event.target)
    setShowOverlay(true)
    event.stopPropagation()
  }

  const popover = () => (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Delete resource</Popover.Title>
      <Popover.Content>
        <p>
          Are you sure?
        </p>
        <ButtonToolbar>
          <Button size="sm" variant="secondary" onClick={hideOverlay} disabled={loading}>
            Cancel
          </Button>
          &nbsp;
          <Button size="sm" variant="danger" onClick={deleteResource} disabled={loading}>
            <span>Delete</span>
            {loading &&
            <Spinner animation="border" role="status" size="sm" style={{marginLeft: 10}}>
              <span className="sr-only">Loading...</span>
            </Spinner>}
          </Button>
        </ButtonToolbar>
      </Popover.Content>
    </Popover>
  )

  return (
    <React.Fragment>
      <Button disabled={!resource.canDestroy()}
              onClick={handleClick}
              variant={variant || 'link'}
              size={size || ''}
              title="Delete">
        <FontAwesomeIcon icon={faTrashAlt} />
        {label ? <span>&nbsp;Delete</span> : ''}
      </Button>
      <Overlay show={showOverlay} target={overlayTarget} placement="left">
        {popover()}
      </Overlay>
    </React.Fragment>
  )
}