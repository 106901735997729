import React, {useState} from 'react'
import {Button, Col, Form, ListGroup, Row} from "react-bootstrap"
import styles from "../styles/UserGroupModal.module.css"
import FormSelect from "../lib/FormSelect"
import Role from "../resources/Role"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons"
import ResourceTypeahead from "./ResourceTypeahead"
import User from '../resources/User'

export default ({ userGroup, roles, setRoles }) => {
  const [user, setUser] = useState(null)

  const addRole = () => {
    if (!user) {
      return
    }
    setRoles(roles.concat({
      resource: Role.build({ userGroup: userGroup, user: user }),
      state: {roleType: 'basic'}
    }))
    setUser(null)
  }

  const handleUserSelect = (user) => {
    setUser(user)
  }

  const handleRoleChange = (ev, role) => {
    role.state[ev.target.name] = ev.target.value
    const newRoles = [...roles]
    newRoles.splice(roles.findIndex(r => r === role), 1, role)
    setRoles(newRoles)
  }

  const removeRole = (role) => {
    setRoles(roles.filter(r => r !== role))
  }

  const excludeIds = roles.map(r => r && r.resource.user && r.resource.user() && r.resource.user().id)

  return (
    <Form>
      <ListGroup variant="flush">
        {roles.map((role, index) => (
          <ListGroup.Item key={`role-${index}`}>
            <Row noGutters>
              <Col md={8}>
                <span className={styles.userName}>{role.resource.user().uniqueName()}</span>
              </Col>
              <Col xs={3}>
                <FormSelect
                  size="sm"
                  name="roleType"
                  label=""
                  className={styles.roleTypeSelector}
                  options={Role.TYPES}
                  value={role.state.roleType}
                  onChange={(ev) => handleRoleChange(ev, role)}/>
              </Col>
              <Col xs={1}>
                {role.resource.canDestroy() &&
                <Button className="float-right"
                        variant="link-danger"
                        size="sm"
                        onClick={() => removeRole(role)}>
                  <FontAwesomeIcon icon={faTimes} />
                </Button>}
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Form.Group>
        <ResourceTypeahead
          name="userId"
          label=""
          placeholder="- search user -"
          excludeIds={excludeIds}
          value={user}
          onChange={handleUserSelect}
          baseQuery={User.order({ name: 'asc' })}
          labelResolver={(res) => res.uniqueName()}
        />
        <Button
          onClick={addRole}
          size='sm'
          variant='dark'
          disabled={user === null}
        >
          <FontAwesomeIcon icon={faPlus} />&nbsp;
          Add user
        </Button>
      </Form.Group>
    </Form>
  )
}