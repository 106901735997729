import React, {useContext, useEffect, useState} from 'react'
import ResourceModal from "./ResourceModal"
import FormDateTimePicker from "../lib/FormDateTimePicker"
import {ResourceContext} from "../contexts/ResourceContext"

export default ({resource, show, onClose}) => {
  const { reloadResources } = useContext(ResourceContext)
  const [formState, setFormState] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(
    () => {
      setFormState({takeOnlineAt: new Date().toJSON()})
      setErrors({})
    },
    [resource]
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    setFormState({...formState, [name]: value})
  }

  const save = () => {
    if (!formState.takeOnlineAt || formState.takeOnlineAt === '') {
      setErrors({takeOnlineAt: 'must be given'})
      return
    }
    let state = Object.assign({}, formState)
    resource.assignAttributes({...state})
    return resource.save().then(() => {
      reloadResources("Instrument put online")
      onClose()
    }).catch(_err => {
      setErrors({takeOnlineAt: 'is invalid'})
    })
  }

  if (!resource) {
    return null
  }

  return (
    <ResourceModal show={show} onClose={onClose} onSubmit={save} resource={resource} title="Put instrument online" submitLabel="Create">
      {resource.fullName}
      <hr/>
      {resource.group &&
      <p><strong>Create online calibration for the group and all its child instruments</strong></p>}
      {!resource.group &&
      <p><strong>Create online calibration for the instrument</strong></p>}
      <FormDateTimePicker name="takeOnlineAt"
                          label="Online from time"
                          horizontal
                          value={formState.takeOnlineAt}
                          error={errors.takeOnlineAt}
                          onChange={handleInputChange}/>
    </ResourceModal>
  )
}