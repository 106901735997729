import React from "react"
import Logo from "../images/2kLogo2.jpeg"
import HeaderStyles from "../styles/themes/TwoK/Header.module.css"
import FooterStyles from "../styles/themes/TwoK/Footer.module.css"
import packageJson from "../../package.json"
import {Link} from "react-router-dom"

export default class TwoK {
  static init() {
    import('../styles/themes/TwoK/general.css').then((_condition) => {});
  }

  static Header({children}) {
    return (
      <div className={HeaderStyles.header}>
        <div className={HeaderStyles.logo}>
          <img src={Logo} alt="TwoK"/>
        </div>
        <span className={HeaderStyles.title}>SensorMonitor</span>
        {children}
      </div>
    )
  }

  static Footer() {
    return (
      <div className={FooterStyles.container}>
        v{packageJson.version} - &copy; {new Date().getFullYear()} Insinööritoimisto 2K / SensorMonitor
        &nbsp;|&nbsp;
        <Link to="/tietosuojaseloste" target="_blank">Tietosuojaseloste</Link>
      </div>
    )
  }
}
