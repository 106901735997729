import React, { useState, useEffect } from 'react'
import { Table, ButtonToolbar } from 'react-bootstrap'
import { InstrumentCalibration, InstrumentCalibrationSensor } from "../Resources"
import ResourceList from "../components/ResourceList"
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import ResourceEditButton from "../components/ResourceEditButton"
import InstrumentCalibrationModal from "../components/InstrumentCalibrationModal"
import ResourceCreateButton from "../components/ResourceCreateButton"
import Time from "../lib/Time"
import uncamelize from "../lib/uncamelize"

export default ({instrument}) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)
  const [siblingCalibration, setSiblingCalibration] = useState(null)
  const [instrumentCalibrationSensors, setInstrumentCalibrationSensors] = useState([])

  useEffect(() => {
    if (instrument){
      instrument.siblings().includes('instrumentCalibrations.instrumentCalibrationSensors.sensor')
        .perPage(1).all().then(siblings => resolveSiblingCalibration(siblings))
    }
  }, [instrument])

  const resolveSiblingCalibration = (siblings) => {
    if (siblings.size() === 0)
      return
    const calibs = siblings.toArray()[0].instrumentCalibrations().toArray().sort((a, b) => a.validFrom < b.validFrom)
    if (calibs.length === 0)
      return
    setSiblingCalibration(calibs[0])
  }

  const editResource = (resource) => {
    setInstrumentCalibrationSensors(resource.instrumentCalibrationSensors().toArray())
    setSelectedResource(resource)
    setShowModal(true)
  }

  const newResource = (ev, resources) => {
    let props = {}
    let calibSensors = []
    let calib = null
    if (resources && resources.size() > 0){
      // Copy attributes from the latest calibration
      calib = resources.toArray()[0]
    } else if (siblingCalibration){
      // Copy attributes from the sibling instrument calibration
      calib = siblingCalibration
    }
    if (calib) {
      Object.keys(calib.__fields).forEach(k => {
        if (calib[k] !== null && k !== 'instrumentCalibrationSensors') {
          props[k] = calib[k]
        }
      })
      calib.instrumentCalibrationSensors().toArray().forEach(s => {
        if (instrument.requiredSensors.map(s => s.key).indexOf(s.key) !== -1 ||
            instrument.optionalSensors.map(s => s.key).indexOf(s.key) !== -1)
        calibSensors.push(InstrumentCalibrationSensor.build({
          sensor: s.sensor(),
          key: s.key
        }))
      })
    }
    props.validFrom = new Date().toJSON()
    props.instrument = instrument
    props.offline = false
    setInstrumentCalibrationSensors(calibSensors)
    setSelectedResource(InstrumentCalibration.build(props))
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedResource(null)
    setShowModal(false)
  }

  return (
    <React.Fragment>
    {instrument &&
    <ResourceContext baseQuery={instrument.instrumentCalibrations().includes('instrumentCalibrationSensors.sensor', 'instrument').order({valid_from: 'desc'})}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="instrumentCalibrations">
          New calibration
        </ResourceCreateButton>
      </ButtonToolbar>
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>Valid from</th>
          <th>Location</th>
          <th>Parameters</th>
          <th>Devices</th>
          <th className="actions"></th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <tr key={resource.id}>
            <td><Time>{resource.validFrom}</Time></td>
            {!resource.offline &&
            <React.Fragment>
              <td>{resource.location()}</td>
              <td>{instrument.requiredAttributes.map(k => `${uncamelize(k)}: ${resource.characteristics[k] !== undefined ? resource.characteristics[k] : '-'}`).join(', ')}</td>
              <td>{resource.sensorNames().join(', ')}</td>
            </React.Fragment>}
            {resource.offline &&
            <React.Fragment>
              <td colSpan={3} style={{textAlign: 'center'}}>- OFFLINE -</td>
            </React.Fragment>}
            <td className="actions">
              <ResourceEditButton resource={resource} onClick={() => editResource(resource)}/>
              <ResourceDeleteButton resource={resource}/>
            </td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <InstrumentCalibrationModal resource={selectedResource}
                                  show={showModal}
                                  onClose={closeModal}
                                  instrumentCalibrationSensors={instrumentCalibrationSensors}/>
    </ResourceContext>}
    </React.Fragment>
  )
}