import React, {useContext, useEffect, useState} from 'react'
import FormInput from '../lib/FormInput'
import FormDateTimePicker from "../lib/FormDateTimePicker"
import {Organization} from "../Resources"
import FormSelect from "../lib/FormSelect"
import ResourceModal from "./ResourceModal"
import {ResourceContext} from "../contexts/ResourceContext"
import useForm from "../lib/useForm"
import {RootContext} from "../contexts/RootContext"
import FormCheckbox from "../lib/FormCheckbox"

export default ({resource, show, onClose}) => {
  const { formState, setFormState, initFormState, handleInputChange } = useForm()
  const [ organizations, setOrganizations ] = useState([])
  const { reloadResources } = useContext(ResourceContext)
  const [ errors, setErrors ] = useState({})
  const { auth } = useContext(RootContext)

  useEffect(
    () => {
      setErrors({})
      if (resource){
        initFormState(resource)
        // Load organizations for parent selector
        Organization.where({'allow_children': true}).perPage(500).order({'name': 'asc'}).all().then(orgs =>
          setOrganizations(orgs.toArray().filter(org => org.id !== resource.id))
        )
      }
    },
    [resource]
  )

  const save = () => {
    if (!auth.user.admin && !formState.parent) {
      formState.parent = organizations[0]
    }
    resource.assignAttributes(formState)
    const msg = resource.persisted() ? 'Organization updated' : 'Organization created'
    return resource.save().then(async () => {
      reloadResources(msg)
      onClose()
    }).catch(err => {
      let errors = {}
      console.log("Error saving the organization: " + err)
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  const parentOptions = () => {
    let options = organizations.map(org => [org.id, org.name])
    if (auth.user.admin) {
      options = [[null, '- none -'], ...options]
    }
    return options
  }

  const selectParent = (ev) => {
    const parent = organizations.find(org => org.id === ev.target.value)
    setFormState({ ...formState, parent })
  }

  return (
    <ResourceModal show={show} onClose={onClose} onSubmit={save} resource={resource} label="organization">
      <FormSelect
        horizontal
        name="parentId"
        label="Root organization"
        options={parentOptions()}
        value={formState.parent && formState.parent.id}
        error={errors.parent}
        onChange={selectParent}
      />
      {auth.user.admin &&
      <FormCheckbox
        horizontal
        name="allowChildren"
        value={formState.allowChildren}
        errors={errors.allowChildren}
        onChange={handleInputChange}
        disabled={formState.parent}
      />}
      <FormInput
        horizontal
        name="name"
        value={formState.name}
        error={errors.name}
        onChange={handleInputChange}
      />
      <FormInput rows={8} name="remark" value={formState.remark} error={errors.remark} onChange={handleInputChange}/>
    </ResourceModal>
  )
}