import React, {useContext, useRef} from "react"
import { ResourceContext } from "../contexts/ResourceContext"
import Url from "../lib/Url"
import styles from '../styles/ResourceFilter.module.css'

export default ({children}) => {
  const { filter, setFilter, setPage } = useContext(ResourceContext)
  const timeoutRef = useRef(null)

  const handleFilterChange = (ev, additional = null) => {
    let newFilter = {...filter}
    if (ev) {
      const name = ev.target.name
      const value = ev.target.value
      newFilter[name] = value !== '' ? value : undefined
    }
    if (additional) {
      newFilter = {...newFilter, ...additional}
    }
    setPage(1)
    setFilter(newFilter)
    window.clearTimeout(timeoutRef.current)
    timeoutRef.current = window.setTimeout(() => {
      Url.setQueryParams({filter: newFilter, page: 1})
    }, 250)
  }

  return (
    <div className={styles.container}>
      {children(filter, handleFilterChange)}
    </div>
  )
}