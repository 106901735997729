import Base from './Base'

class Organization extends Base {
  static className = 'Organization'
  static define() {
    this.belongsTo('parent', { className: 'Organization' })
    this.hasMany('children', { className: 'Organization' })
    this.hasMany('userGroups')
    this.hasMany('instruments')
    this.hasMany('organizationDevices')
    this.hasMany('tags')
    this.hasMany('commands')
    this.hasMany('automations')
    this.hasMany('instrumentStatusReports')
    this.hasOne('deviceActivityReport')
    this.attributes('name', 'contractEndDate', 'remark', 'allowChildren')
  }

  contractHasEnded(){
    if (!this.contractEndDate)
      return false
    return Date.parse(this.contractEndDate + 'T00:00:00Z') < Date.now()
  }
}

export default Base.createResource(Organization)