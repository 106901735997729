import React, { useState, useEffect } from 'react'
import {Spinner, Table} from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { Sensor } from "../Resources"
import ResourceList from "../components/ResourceList"
import ResourceListSortTh from "../components/ResourceListSortTh"
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import Breadcrumbs from "../lib/Breadcrumbs";
import Time from "../lib/Time"

export default () => {
  const [ sensor, setSensor ] = useState(null)
  const { sensorId } = useParams()
  const [ loading, setLoading ] = useState(false)

  useEffect(
    () => {
      if (sensorId){
        setLoading(true)
        Sensor.find(sensorId).then(res => {
          setSensor(res)
          setLoading(false)
        }).catch(err => {
          console.error(err)
          setLoading(false)
        })
      }
    },
    [sensorId]
  )

  return (
    <div>
      {loading &&
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>}
      {sensor &&
      <ResourceContext baseQuery={sensor.data()} defaultSort={{received_at: 'desc'}}>
        <Breadcrumbs>
          <Link to="/devices">
            Devices
          </Link>
          <Link to={`/devices/${sensor.name}/sensors`}>
            {`${sensor.name} (${sensor.model})`}
          </Link>
          {`${sensor.parameter} (${sensor.unit})`}
        </Breadcrumbs>
        <h4>Data</h4>
        <Table striped bordered hover>
          <thead>
          <tr>
            <ResourceListSortTh name={'received_at'}/>
            <ResourceListSortTh name={'value'}/>
          </tr>
          </thead>
          <tbody>
          <ResourceList>
            {resource => <tr key={resource.id}>
              <td><Time>{resource.receivedAt}</Time></td>
              <td>{resource.value}</td>
            </tr>}
          </ResourceList>
          </tbody>
        </Table>
        <ResourceListPagination/>
      </ResourceContext>}
    </div>
  )
}