import React, {useContext, useEffect, useRef, useState} from 'react'
import styles from "../styles/InstrumentWidget.module.css"
import InstrumentWidget from './InstrumentWidget'
import InstrumentWidgetRow from "./InstrumentWidgetRow"
import {InstrumentStatusContext} from "../contexts/InstrumentStatusContext"
import {Spinner} from "react-bootstrap"
import {elementPartiallyInViewport} from "../lib/viewport"
import useWindowScrollOffsets from "../lib/useWindowScrollOffsets"
import useWindowDimensions from "../lib/useWindowDimensions"

export default ({instrument, selectInstrument, level}) => {
  const [ children, setChildren] = useState([])
  const { addInstruments } = useContext(InstrumentStatusContext)
  const [ loading, setLoading ] = useState(false)
  const loadedRef = useRef(false)
  const { scrollX, scrollY } = useWindowScrollOffsets()
  const { windowWidth, windowHeight } = useWindowDimensions()
  const containerRef = useRef(null)

  useEffect(() => {
    if (instrument.group && !instrument.children().empty()){
      loadedRef.current = true
      setChildren(instrument.children().toArray())
    } else if (instrument.group) {
      window.setTimeout(() => loadChildren(), 250)
    }
  }, [])


  useEffect(() => {
    if (instrument.group) {
      loadChildren()
    }
  }, [scrollX, scrollY, windowWidth, windowHeight])

  const loadChildren = () => {
    if (loadedRef.current || (!level && !elementPartiallyInViewport(containerRef.current))) {
      return
    }
    setLoading(true)
    loadedRef.current = true
    instrument.children().order({group: 'asc', name: 'asc'}).load().then(children => {
      setLoading(false)
      children.each(c => c.assignParent(instrument))
      addInstruments(children.toArray())
      setChildren(children.toArray())
    })
  }

  const handleClick = (instrument) => {
    selectInstrument(instrument)
  }

  const minHeight = () => {
    if (level || !instrument.descendantCount) {
      return null
    }
    return instrument.descendantCount * 45
  }

  return (
    <div style={{minHeight: minHeight()}}
         className={[styles.container, styles[`containerLevel${level || 1}`]].join(' ')}
         ref={containerRef}>
      <InstrumentWidgetRow instrument={instrument}
                           onClick={handleClick}
                           iconClassName={instrument.group && level ? styles.groupIcon : null}
                           className={!level && instrument.group ? styles.rootGroup : (instrument.group ? styles.group : styles.root)}/>
      {loading &&
      <Spinner animation="border" role="status" className={styles.spinner} style={{margin: 10}}>
        <span className="sr-only">Loading...</span>
      </Spinner>}
      {!loading && children.map((child, i) => {
        if (child.group)
          return <InstrumentWidget instrument={child} selectInstrument={selectInstrument} level={(level || 1) + 1} key={child.id}/>
        else
          return <InstrumentWidgetRow instrument={child}
                                      className={[styles.child, !(i % 2) ? styles.oddChild : null].join(' ')}
                                      onClick={handleClick}
                                      iconClassName={styles.childIcon}
                                      key={child.id}/>
      })}
    </div>
  )
}