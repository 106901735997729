import Base from './Base'

class Automation extends Base {
  static className = 'Automation'

  static define() {
    this.belongsTo('organization')
    this.attributes('name', 'conditions', 'consequences', 'disabled', 'disabledAt')
  }
}

export default Base.createResource(Automation)
