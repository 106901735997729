import React, {useState} from "react"
import FormCheckbox from "../lib/FormCheckbox"
import FormInput from "../lib/FormInput"

export default ({resource, errors, formState, handleInputChange}) => {
  const [show, setShow] = useState(false)

  return (
    <React.Fragment>
      <FormCheckbox name="showTimeSettings" label="Time settings" checked={show} onChange={() => setShow(!show)} />
      {show &&
      <div>
        {resource && resource.instrument().dataAttributes.length > 0 &&
        <FormInput name="offRangeThresholdMins"
                   label="Value off range tolerance (minutes)"
                   horizontal={4}
                   value={formState.offRangeThresholdMins}
                   error={errors.offRangeThresholdMins}
                   onChange={handleInputChange}/>}
        {resource && !resource.instrument().group &&
        <FormInput name="dataAgeThresholdMins"
                   label="Mute sensor tolerance (minutes)"
                   horizontal={4}
                   value={formState.dataAgeThresholdMins}
                   error={errors.dataAgeThresholdMins}
                   onChange={handleInputChange}/>}
        {resource && !resource.instrument().group &&
        <FormInput name="stallingThresholdMins"
                   label="Stalling value tolerance (minutes)"
                   horizontal={4}
                   value={formState.stallingThresholdMins}
                   error={errors.stallingThresholdMins}
                   onChange={handleInputChange}/>}
        <FormInput name="minEventIntervalMins"
                   label="Minimum event interval (minutes)"
                   horizontal={4}
                   value={formState.minEventIntervalMins}
                   error={errors.minEventIntervalMins}
                   onChange={handleInputChange}/>
      </div>}
    </React.Fragment>
  )
}