import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

const formats = {
  date: {
    years: 'YYYY',
    months: 'MMM YYYY',
    days: 'MMM Do YYYY',
    monthAndDay: 'MMM Do'
  },
  time: {
    minutes: 'HH:mm',
    seconds: 'HH:mm:ss',
    milliseconds: 'HH:mm:ss.SSS'
  }
}

class Time extends React.Component{
  render(){
    return (
      <span className={"time-component " + (this.props.className ? this.props.className : '')}>
        {Time.formatTime(this.props.children, this.props.dateFormat, this.props.timeFormat)}
      </span>
    )
  }

  static parseUTC(jsonTime){
    //console.log(k);

    let date = jsonTime.split('T')[0].split('-')
    let time = jsonTime.split('T')[1].replace('Z','').split(':')

    //console.log(date);
    //console.log(time);

    //let ts = Date.parse(k);

    const year = parseInt(date[0], 10)
    const month = parseInt(date[1], 10) - 1
    const day = parseInt(date[2], 10)
    const hour = parseInt(time[0], 10)
    const minute = parseInt(time[1], 10)
    const second = parseInt(time[2].split('.')[0], 10)
    const milliSecond = parseInt(time[2].split('.')[1], 10)

    return Date.UTC(year, month, day, hour, minute, second, milliSecond)
  }

  static timezone(){
    return moment.tz.guess() || 'Europe/Helsinki'
  }

  static formatTime(time, dateFormat = 'days', timeFormat = 'seconds'){
    if (!time)
      return ''
    const dF = formats.date[dateFormat] || dateFormat
    const tF = formats.time[timeFormat] || timeFormat
    return moment(time).tz(Time.timezone()).format([dF, tF].filter(i => i).join(' '))
  }

  static durationToNow(fromTime, postfix = ''){
    if (!fromTime) {
      return ''
    }
    let duration = this.formatDuration(moment().diff(fromTime))
    if (postfix) {
      duration += ` ${postfix}`
    }
    return duration
  }

  static duration(ms){
    if (ms === null || isNaN(ms))
      return [[]]
    if (ms <= 1000)
      return [[Math.round(ms), 'ms']]
    else if (ms <= 1000 * 60)
      return [[Math.floor(ms / 1000), 'sec']]
    else if (ms <= 1000 * 60 * 60){
      const min = Math.floor(ms / 60000)
      const sec = Math.round(ms % 60000 / 1000)
      if (sec > 0 && min < 10)
        return  [[min, 'min'], [sec, 'sec']]
      else
        return  [[min, 'min']]
    } else if (ms <= 1000 * 60 * 60 * 48){
      const hour = Math.floor(ms / 3600000)
      const min = Math.floor((ms / 60000) % 60)
      //const sec = Math.round(ms % 60000 / 1000)
      if (min > 0 && hour < 10)
        return  [[hour,'h'], [min, 'min']]
      else
        return [[hour, 'h']]
    } else {
      const days = Math.floor(ms / (1000 * 60 * 60 * 24))
      return [[days, 'days']]
    }
  }

  static formatDuration(ms){
    return this.duration(ms).flat().join(' ')
  }
}

Time.Zone = () => {
  return <span className="time-component timezone">tz:&nbsp;{Time.timezone()}</span>
}

Time.Duration = (props) => {
  return <span className="time-component duration">{Time.formatDuration(props.children)}</span>
}

Time.DurationToNow = (props) => {
  return <span className="time-component duration">{Time.durationToNow(props.children, props.postfix)}</span>
}

Time.propTypes = {
  time: PropTypes.any,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string
}

export default Time