import React, {useEffect, useState} from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import InstrumentAlarmModal from "../components/InstrumentAlarmModal"
import InstrumentAlarmDropdown from "../components/InstrumentAlarmDropdown"
import { InstrumentAlarm } from "../Resources"
import InstrumentAlarmEventIndex from "../components/InstrumentAlarmEventIndex"

export default ({instrument}) => {
  const [ showModal, setShowModal ] = useState(false)
  const [ alarm, setAlarm ] = useState(null)

  useEffect(() => {
    if (instrument && instrument.instrumentAlarm && instrument.instrumentAlarm()) {
      setAlarm(instrument.instrumentAlarm())
    } else if (instrument) {
      const alarm = InstrumentAlarm.build({
        instrument: instrument,
        dataAgeThresholdMins: 360,
        offRangeThresholdMins: 0,
        stallingThresholdMins: 0,
        minEventIntervalMins: 5
      })
      instrument.assignInstrumentAlarm(alarm)
      setAlarm(alarm)
    }
  }, [instrument])

  const closeModal = () => {
    setShowModal(false)
    alarmUpdated()
  }

  const alarmUpdated = () => {
    const a = alarm
    setAlarm(null)
    setAlarm(a)
    setTimeout(() => setAlarm(a), 50)
  }

  return (
    <React.Fragment>
      {alarm &&
      <React.Fragment>
        <ButtonToolbar className="float-right">
          <InstrumentAlarmDropdown disabled={!instrument.canUpdate()}
                                   onUpdate={alarmUpdated}
                                   alarm={alarm}
                                   setShowModal={setShowModal}/>
        </ButtonToolbar>
        {alarm.persisted() && <InstrumentAlarmEventIndex alarm={alarm}/>}
      </React.Fragment>}
      <InstrumentAlarmModal resource={alarm} show={showModal} onClose={closeModal}/>
    </React.Fragment>
  )
}