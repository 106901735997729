import Base from './Base'

class InstrumentCalibration extends Base {
  static className = 'InstrumentCalibration'
  static define() {
    this.belongsTo('instrument')
    this.hasMany('instrumentCalibrationSensors')
    this.attributes(
      '',
      'validFrom',
      'longitude',
      'latitude',
      'offline',
      'characteristics'
    )
  }

  sensorNames(){
    if (!this.instrumentCalibrationSensors || !this.instrumentCalibrationSensors())
      return []
    const names = this.instrumentCalibrationSensors().toArray()
      .map(obj => obj.sensor && obj.sensor() && obj.sensor().name)
    // Uniq
    return names.filter((item, index) => names.indexOf(item) === index)
  }

  location(){
    if (this.latitude && this.longitude)
      return [this.latitude, this.longitude].join(', ')
    else
      return ''
  }
}

export default Base.createResource(InstrumentCalibration)