import React, {useState, useEffect} from 'react'
import {Button, Table, Form, Row, Col} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import ResourceList from "../components/ResourceList"
import Sensor from "../resources/Sensor"
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import Breadcrumbs from '../lib/Breadcrumbs'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronRight} from "@fortawesome/free-solid-svg-icons"
import FormSelect from "../lib/FormSelect"
import ResourceFilter from "../components/ResourceFilter"
import ResourceListSortTh from "../components/ResourceListSortTh"
import Time from '../lib/Time'
import FormTypeahead from "../lib/FormTypeahead"
import ExpandableList from "../lib/ExpandableList"
import useWindowDimensions from "../lib/useWindowDimensions"

export default ({device}) => {
  const { windowWidth } = useWindowDimensions()
  const [sensors, setSensors] = useState([]) // For filter
  const history = useHistory()
  let typeaheadTimeout = null

  useEffect(
    () => {
      if (!device)
        loadSensors('')
    },
    []
  )

  const showResource = (resource) => {
    history.push(`/sensors/${resource.id}/data`)
  }

  const loadSensors = (search) => {
    Sensor.where({search}).order({name: 'asc'}).perPage(100).all().then(result => {
      setSensors(result.toArray())
    })
  }

  const handleSearchTypeaheadInput = (value) => {
    if (typeaheadTimeout)
      window.clearTimeout(typeaheadTimeout)
    typeaheadTimeout = window.setTimeout(() => loadSensors(value), 500)
  }

  const filter = (filter, handleFilterChange) => (
    <Form>
      <Row className="justify-content-md-left">
        <Col md="auto">
          <FormTypeahead name="name"
                         label="Device ID"
                         placeholder="- all -"
                         options={sensors.map(s => s.name).filter((v, i, a) => a.indexOf(v) === i)}
                         value={filter.name}
                         clearButton
                         onInputChange={handleSearchTypeaheadInput}
                         onChange={handleFilterChange}/>
        </Col>
        <Col md="auto">
          <FormSelect name="parameter"
                      label="Parameter"
                      options={[
                        'distance',
                        'temp',
                        'battery voltage',
                        'battery level',
                        'humidity',
                        'motion',
                        'illuminance',
                        'co2',
                        'coordinates',
                        'pressure',
                        'spl',
                        'passing'
                      ]}
                      blank="- all -"
                      value={filter.parameter}
                      onChange={handleFilterChange}/>
        </Col>
      </Row>
    </Form>
  )

  const baseQuery = () => (
    Sensor.includes('instruments').where(device ? {name: device.id} : {})
  )

  const InstrumentLink = ({instrument}) => (
    <React.Fragment>
      <Button variant="link" size="sm" onClick={(ev) => {
        history.push(`/instruments/${instrument.id}`)
        ev.stopPropagation()
      }}>
        <div style={{display: 'inline-block',
          maxWidth: Math.max(100, windowWidth / 4),
          fontSize: '0.9em',
          overflow: 'hidden',
          textOverflow: 'ellipsis'}}>
          {instrument.fullName}
        </div>
      </Button>
      <br/>
    </React.Fragment>
  )

  return (
    <ResourceContext baseQuery={baseQuery()} defaultSort={{name: 'asc'}}>
      {!device && <React.Fragment>
        <Breadcrumbs>
          Sensors
        </Breadcrumbs>
        <ResourceFilter>
          {filter}
        </ResourceFilter>
      </React.Fragment>}
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          {!device && <React.Fragment>
            <ResourceListSortTh name="name">Device ID</ResourceListSortTh>
            <ResourceListSortTh name="model"/>
          </React.Fragment>}
          <ResourceListSortTh name="parameter"/>
          <ResourceListSortTh name="latest_data.value">Latest value</ResourceListSortTh>
          <ResourceListSortTh name="latest_data.received_at">Data received</ResourceListSortTh>
          <th>Instruments</th>
          <th className="actions"></th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <tr className="clickable" key={resource.id} onClick={() => showResource(resource)}>
            {!device && <React.Fragment>
              <td>{resource.name}</td>
              <td>{resource.model}</td>
            </React.Fragment>}
            <td>{resource.parameter}</td>
            <td>{resource.latestDataValue} {resource.unit !== 'n' ? resource.unit : ''}</td>
            <td><Time.DurationToNow postfix="ago">{resource.latestDataReceivedAt}</Time.DurationToNow></td>
            <td>
              <ExpandableList>
                {resource.instruments().toArray().map(i => i && <InstrumentLink key={i.id} instrument={i}/>)}
              </ExpandableList>
            </td>
            <td className="actions">
              <Button variant="link">
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
    </ResourceContext>
  )
}