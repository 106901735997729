import React, { useState } from 'react'
import { Table, ButtonToolbar } from 'react-bootstrap'
import { Tag } from "../Resources"
import ResourceList from "../components/ResourceList"
import ResourceListSortTh from '../components/ResourceListSortTh'
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import ResourceEditButton from "../components/ResourceEditButton"
import TagModal from "../components/TagModal"
import ResourceCreateButton from "../components/ResourceCreateButton"

export default ({organization}) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)

  const editResource = (resource) => {
    setSelectedResource(resource)
    setShowModal(true)
  }

  const newResource = () => {
    setSelectedResource(Tag.build({organization: organization}))
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedResource(null)
    setShowModal(false)
  }

  const baseQuery = () => {
    if (organization)
      return organization.tags().includes('organization')
    else
      return Tag.includes('organization')
  }

  return (
    <ResourceContext baseQuery={baseQuery()} defaultSort={{name: 'asc'}}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="tags">
          New tag
        </ResourceCreateButton>
      </ButtonToolbar>
      <Table striped bordered hover>
        <thead>
        <tr>
          {!organization &&
          <th>Organization</th>}
          <ResourceListSortTh name="name"/>
          <th className="actions"></th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <tr key={resource.id}>
            {!organization &&
            <td>{resource.organization().name}</td>}
            <td>{resource.name}</td>
            <td className="actions">
              <ResourceEditButton resource={resource} onClick={() => editResource(resource)}/>
              <ResourceDeleteButton resource={resource}/>
            </td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <TagModal resource={selectedResource} show={showModal} onClose={closeModal}/>
    </ResourceContext>
  )
}