import { useEffect } from 'react'
import packageJson from '../../package.json'

// Set the name of the hidden property and the change event for visibility
let visibilityChange
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
  visibilityChange = "visibilitychange"
} else if (typeof document.msHidden !== "undefined") {
  visibilityChange = "msvisibilitychange"
} else if (typeof document.webkitHidden !== "undefined") {
  visibilityChange = "webkitvisibilitychange"
}

let timeout = null
let lastConfirmTime = null

const reload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(names => {for (let name of names) caches.delete(name)})
  }
  // delete browser cache and hard reload
  window.location.reload(true)
}

const onFocus = () => {
  if (lastConfirmTime && Date.now() - lastConfirmTime < 60000)
    return
  fetch('/meta.json?t=' + Date.now())
    .then(response => response.json())
    .then(meta => {
      console.log("meta.json loaded")
      const latestVersion = meta.version
      if (latestVersion && latestVersion + '' !== packageJson.version + ''){
        lastConfirmTime = Date.now()
        if (window.confirm("New software version (" + latestVersion + ") available! Press OK to reload"))
          reload()
      }
    })
}

const handleVisibilityChange = () => {
  //console.log("FOCUS")
  if (document.hasFocus()){
    if (timeout)
      window.clearInterval(timeout)
    timeout = window.setTimeout(onFocus, 500)
  }
}

export default () => {
  useEffect(
    () => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Reloader not used in dev or testing')
        return
      }
      // Warn if the browser doesn't support addEventListener or the Page Visibility API
      if (typeof document.addEventListener === "undefined"){
        console.log("Visibility API not supported")
      } else {
        document.addEventListener(visibilityChange, handleVisibilityChange, false)
      }
      window.addEventListener("focus", handleVisibilityChange, false)
      window.addEventListener("pageshow", handleVisibilityChange, false) // For iOS
      return () => {
        document.removeEventListener(visibilityChange, handleVisibilityChange)
        window.removeEventListener("focus", handleVisibilityChange)
        window.removeEventListener("pageshow", handleVisibilityChange)
      }
    },
    []
  )
  return null
}